import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

import { Link } from 'react-router-dom';
import founderImage from './images/founder.png'; // Replace with actual image path
import Slider from 'react-slick'; // Assuming you're using a slider like slick-carousel
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import ohm from './jsons/images/ohmslaw.png';
import ess2 from './jsons/images/ess2.png';
import inter from './jsons/images/interview01.jpg';
import mtn1 from './jsons/images/mtn1.jpg';
import square from './jsons/images/square.jpg';
import showcase from './jsons/images/showcase.jpg';
import students from './jsons/images/students.jpg';
import smile from './jsons/images/smile.jpg';
import hold from './jsons/images/hold.jpg';
import whitesci2 from './jsons/images/bulbwhte.jpg';
import whitesci from './jsons/images/sci2.jpg';
import './about.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import book1 from './jsons/freepic/book1.json';
import brain from './jsons/freepic/brain.json';
import digitalscience from './jsons/freepic/digitalscience.json';
import expand1 from './jsons/freepic/expandteam.json';
import global from './jsons/freepic/global.json';
import mobileapp from './jsons/freepic/mobileapp.json';
import learn from './jsons/freepic/learn.json';
import ms1 from './jsons/images/ms1.jpg';

const exhibit = [
  // Replace with actual image paths for the slider
  '/path/to/image1.jpg',
  '/path/to/image2.jpg',
  '/path/to/image3.jpg',
];

const Founder = () => {
  const exhibit = [inter,students,smile,mtn1,ms1,showcase,hold]; // Add more images or videos as needed
  const parentStyle = {
    background: 'linear-gradient(to right, #2C2575, #91306D)', // Blue gradient
    width: '100vw', // Full width of the viewport
    position: 'relative',

  };
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const wordsref = useRef(null);

  
  useEffect(() => {
    gsap.set([section1Ref.current, section2Ref.current,wordsref.current], { 
      opacity: 0, 
      y: +50 // Start 50px above their current position 
    });
  
    gsap.to(section1Ref.current, {
      duration: 1.7,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 0.3,
    });

    gsap.to(section2Ref.current, {
      duration: 1.5,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 1.5,
    });
    gsap.to(wordsref.current, {
      duration: 1.7,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power4.inOut",
      delay: 1,
    });
  }, []);
  
  
  return (
    <div>
      <div ref={section1Ref} className="container my-5" style={{ backgroundColor: '#f9f9f9', borderRadius: '50px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }}>
        <div className="row align-items-center" style={{ padding: '40px', borderRadius: '25px' }}>
          <div className="col-md-4">
            <img
              src={founderImage}
              alt="Founder"
              className="img-fluid rounded"
              style={{
                border: '4px solid #fff', // Thicker border with white color
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Deeper shadow
            }}
            
            />
          </div>

          <div ref={wordsref} className="col-md-6">
            <h2 style={{ color: '#333', fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '20px' }}>
              Meet the Founder
            </h2>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#555' }}>
              <strong style={{ color: '#007bff' }}>Sam Nkurunungi</strong> is a passionate software engineer and animator who believes in the power of technology and creativity in education.
              He studied <strong>Software Engineering</strong> at <strong>Makerere University</strong> and pursued <strong>Animation</strong> at <strong>Kashu Academy</strong>.
            </p>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#555' }}>
              With a deep love for both fields, Sam enjoys teaching students about <strong>software development</strong> and <strong>2D motion graphics</strong>.
              His goal is to inspire and equip the next generation with the skills to innovate and express their creativity through technology and animation.
            </p>
            <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#555' }}>
              Through Sci-steps, he combines his expertise to create an interactive learning platform that brings science to life with animations, games, and experiments.
            </p>
            <Link to='/about' style={{ textDecoration: 'none', marginTop: '20px', display: 'inline-block' }}>
              <button
                style={{
                  backgroundColor: '#007bff',
                  color: 'white',
                  padding: '12px 24px',
                  borderRadius: '25px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)',
                  transition: 'background-color 0.3s ease, transform 0.3s ease',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#0056b3';
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#007bff';
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              >
                Back
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Mathswipe Section */}
      <div
      ref={section2Ref}
        id="mathswipe"
        className="row align-items-center mb-5 mx-auto"
        style={{
          ...parentStyle,
          borderRadius: '50px',
          padding: '5px',
          gap:'10px',
          padding:'30px'
        }}
      >
        <div style={{ color: 'white', display: 'flex', flexDirection: 'column' }} className="col-md-6 order-md-2">
          <div>
            <h5>Mathswipe Launch 2024</h5>
            <p>
              In July 2024, Sam introduced <strong>Mathswipe</strong> an interactive math learning
              platform. It featured engaging lessons and practice tests for students. The project was
              showcased at the <strong>UgDevSummit 2024</strong>, gaining recognition for its innovative
              approach to education.
            </p>
          </div>
          <br />
          <div>
            <a href="https://mathswipe.com" target="_blank" rel="noopener noreferrer">
              <button
                style={{
                  backgroundColor: '#2796FF',
                  color: 'black',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                }}
              >
                Click Here to try Mathswipe
              </button>
            </a>
          </div>
          <br />
        </div>
        <div
          style={{
            width: '500px',
            height: '400px',
            margin: '0 auto',
            overflow: 'hidden',
            borderRadius: '30px', // Border radius for the slider container
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Box shadow for the slider container
          }}
        >
          <Slider
            autoplay={true}
            autoplaySpeed={3000}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
            dots={true}
            className="slick-carousel-container"
          >
            {exhibit.map((image, index) => (
              <div key={index} style={{ width: '300px', height: '300px' }}>
                <img
                  src={image}
                  alt={`Slideshow ${index}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '30px', // Border radius for images
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Founder;
