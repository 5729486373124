import React, { useEffect, useRef, useState } from "react";
import { Card, Modal, Button } from "react-bootstrap";
import geometryintro from '../jsons/geometry/geometryintro.json';
import geometryintroa from '../jsons/geometry/geometryintroa.json';
import geosamo from '../jsons/geometry/samosa.json';
import tooeasy from '../jsons/geometry/too easy.json';
import correctpent from '../jsons/geometry/correct pentagon.json';
import wrongpent from '../jsons/geometry/wrong pentagon.json';
import pent1 from '../jsons/geometry/pentagon1.json';
import geosound1 from '../sounds/geometry/geometryintro.mp3';
import geosound2 from '../sounds/geometry/geometryintro2.mp3';
import geoqn1 from '../jsons/geometry/geoqn1.json';
import geoqn2 from '../jsons/geometry/shape2.json';
import geosound3 from '../sounds/geometry/whatshape.mp3';
import geosound22 from '../sounds/geometry/Do you know these shapes.mp3';
import notsound from '../jsons/sounds/not1.mp3';
import navsound from '../jsons/sounds/pop.wav';
import correctnot from '../jsons/sounds/correct.wav';
import { gsap } from "gsap";
import { Player } from '@lottiefiles/react-lottie-player';
import attemptbar from '../jsons/attemptbar.json';
import questionbar from '../jsons/questionbar.json';
import nextbutton from '../jsons/nextbtnlong.json';
import wait from '../jsons/wait.json';
import tutorial from '../jsons/tutorialbar.json';
import startbtn from '../jsons/startbutton.json';
import correctsound from '../sounds/geometry/correct1mark.mp3';
import correct1 from '../jsons/geometry/correct1.json';
import correct1b from '../jsons/geometry/correct1b.json';
import wrong1 from '../jsons/geometry/wrong1.json';
import wrong2 from '../jsons/geometry/trianglewrong.json';
import wrongsound from '../sounds/geometry/wronganswer.mp3';
import CircularButtonback from "../bootsrap elements/CircularButtonback";
import rpt from '../jsons/rpt.json';
import ProgressBar from '../LoadingPages/ProgressBar';
import CircularButton from "../bootsrap elements/CircularButton";
import geo2about from '../jsons/geometry/geoabout.json';
import correcthex from '../jsons/geometry/hexacorrect.json';
import hexa from '../jsons/geometry/hexa.json';
import wronghex from '../jsons/geometry/hexawrong.json';
import hardbgsound from '../sounds/geometry/hardbgsound.mp3';
import geocone from '../jsons/geometry/geocone.json';
import conewrong from '../jsons/geometry/cone wrong.json';
import conecorrect1 from '../jsons/geometry/coneright1.json';
import conecorrect2 from '../jsons/geometry/coneright2.json';
import trapc from '../jsons/geometry/trapc.json';

import trapezium from '../jsons/geometry/trapezium.json';


const Geometry = () => {
  
  const playref = useRef(null);

    const opt2ref = useRef(null);
    const opt1ref = useRef(null);
    const opt3ref = useRef(null);
    const opt4ref = useRef(null);
    const animationRef = useRef(null);
    const audioref = useRef(null);
    const audioref2 = useRef(null);
    const audioref3 = useRef(null);
    const [notification, setnotification] = useState(navsound);
    const [bottomanime, setbottomanime] = useState(startbtn);
    const [topanime, settopanime] = useState(0);
    const [buttonAnimation, setButtonAnimation] = useState(nextbutton);
    const [isDisabled, setIsDisabled] = useState(false);
    const nextbuttonref = useRef(null);
    const [progressvalue, setprogressvalue] = useState(0);
    const [bgcolor, setbgcolor] = useState("#715CFE");
    const [counter, setCounter] = useState(1);
    const [sounding, setSounding] = useState(geosound1);
    const [playing, setPlaying] = useState(geometryintroa);
    const [navigation, setnavigation] = useState(false);
    const [hadbeenwrong, sethadbeenwrong] = useState(false);
    const [level, setlevel] = useState("1");
    const [nextlevel, setnextlevel] = useState(false);
    const easyref = useRef(null);
    
    const [currentlevel, setcurrentlevel] = useState('easy');
    const [showModal, setShowModal] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [rating, setRating] = useState(0);
    const rptref = useRef(null);
    const startref = useRef(null);
    const [subtopicno, setsubtopicno] = useState("Shapes");
    const [options, setoptions] = useState(false);
    const [scorevalue, setscorevalue] = useState(0);
    const [place4active, setplace4active] = useState('');
    const [place3active, setplace3active] = useState('');
    const [place3, setplace3] = useState(0);
    const [place4, setplace4] = useState(0);
    const [option1, setoption1] = useState("Circle");
    const [option2, setoption2] = useState("Triangle");
    const [option3, setoption3] = useState("square");
    const [option4, setoption4] = useState("I dont know");
    const [bottomtitle, setbottomtitle] = useState("Attempt Question to continue"); 
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      useEffect(() => {
      if(counter>4){
        setnextlevel(true);

      }
      else{
        setnextlevel(false);
      }
       }, [nextlevel,counter]);

       useEffect(() => {
        if (currentlevel === "easy" && progressvalue === 60) {
          setShowPrompt(true);
        } else if (currentlevel === "hard" && progressvalue === 30) {
          setShowPrompt(true);
        }
      }, [progressvalue, currentlevel]);

    useEffect(() => {
        if(topanime===tutorial){
         setbgcolor('#91306D');
        }
        else{
         setbgcolor("#715CFE");
     
        }
       }, [topanime]);
       useEffect(() => {
     console.log(counter)
       }, [counter]);

    const updateContent = (isGoingBack = false) => {
  resetOptionBackgrounds2(); // Reset backgrounds before updating
  
  if (currentlevel === 'easy') {
      // Handle going backward
      if(counter === 6){
        setoption1("cube");
        setoption2("Oval");
        setoption3("Triangle");
        setoption4("I don't Know");
        setPlaying(geoqn2);
      }
      if (counter === 5) {
        setnavigation(false);
        settopanime(questionbar);
        setPlaying(geoqn1);
        setbottomtitle("What Shape is this?");
        setSounding(geosound3);
      } else if (counter === 4) {
        settopanime(tutorial);
        setPlaying(geo2about);
        setSounding(geosound22);
      } else if (counter === 3) {
        settopanime(tutorial);
        setPlaying(geometryintro);
        setSounding(geosound2);
      } else if (counter === 2) {
        settopanime(tutorial);
        setPlaying(geometryintro);
        setSounding(geosound2);
      }
    
  }
  else if (currentlevel === 'hard'){
    if(counter === 6){
      setoption1("cube");
      setoption2("Oval");
      setoption3("Triangle");
      setoption4("I don't Know");
      setPlaying(geoqn2);
    }
    if (counter === 3) {
      setnavigation(false);
      settopanime(questionbar);
      setPlaying(geoqn1);
      setbottomtitle("What Shape is this?");
      setSounding(geosound3);
    } else if (counter === 4) {
      setoption1("rectangle");
      setoption2("square");
      setoption3("rhombus");
      setoption4("trapezium");
      settopanime(questionbar);
      setPlaying(trapc);
    } else if (counter === 3) {
      setoption1("Octagon");
      setoption1("rectangle");
      setoption2("square");
      setoption3("rhombus");
      setoption4("trapezium");
          settopanime(questionbar);
      setPlaying(hexa);
      playAnimationAndSound();
    } else if (counter === 2) {
      setoption1("Octagon");
          setoption2("Hexagon");
          setoption3("Decagon");
          setoption4("Pentagon");
          settopanime(questionbar);
          setPlaying(pent1);
          playAnimationAndSound();
    }
  }
  
  playAnimationAndSound();
};

      const playsound = () => {
        if (audioref2.current) {
          audioref2.current.play();
        }
      };
      const playnotification = () => {
        setnotification(navsound);
        setTimeout(() => {
          if(audioref3.current){
            audioref3.current.play();
                  } 
        }, 100);
       
      };
      const handleRepeat = () => {
        setCounter(1);
        setcurrentlevel('easy')
        setShowModal(false); // Close the modal
      };
    

      const playwrongsound = () => {
        setnotification(wrongsound);
        setTimeout(() => {
          if(audioref3.current){
            audioref3.current.play();
                  } 
        }, 100);
       
      };
      const playcorrectsound = () => {
        setnotification(correctnot);
        setTimeout(() => {
          if(audioref3.current){
            audioref3.current.play();
                  } 
        }, 100);
       
      };
    
       const next = () => {
        playnotification();
        setprogressvalue(progressvalue+10);
        if (!isDisabled) {
          setButtonAnimation(wait);
          if (nextbuttonref.current) {
            nextbuttonref.current.play();
          }
          setIsDisabled(true);
          setTimeout(() => {
            setIsDisabled(false);
            setButtonAnimation(nextbutton);
          }, 2500);
        }
        updateContent2();
      };  
      
      const goback = () => {
        if (counter > 1) { // Prevent counter from going below 1
          setCounter(counter - 1);
          setTimeout(() => {
            if (animationRef.current) {
              animationRef.current.stop();
              updateContent(true);  // Pass a parameter to indicate going backward
            }
          }, 500);
        }
      };
      
      const repeatanimation = () => {
        if (animationRef.current) {
          animationRef.current.stop();  // Reset the animation
          animationRef.current.play();  // Play the animation from the start
        }
        if (audioref2.current) {
          audioref2.current.play();  // Play the audio
        }
        playnarrator();
        playsound();
      };
      const handleanimationplay = () => {
        if (animationRef.current) {
          animationRef.current.play();
        }
      };
      
      const playAnimationAndSound = () => {
       // setnavigation(true);
        setTimeout(() => {
          if (animationRef.current) {
            animationRef.current.play();
          }
          if (audioref2.current) {
            audioref2.current.play();
          }
        }, 500);
       
        setTimeout(() => {
          setbottomanime(attemptbar);
        }, 1000);
      };
      const playAnimationAndSound2 = () => {
        setnavigation(true);
        playnotification();

        setTimeout(() => {
          if (animationRef.current) {
            animationRef.current.play();
          }
          if (audioref2.current) {
            audioref2.current.play();
          }
        }, 500);
       
        setTimeout(() => {
          setbottomanime(attemptbar);
        }, 1000);
      };
      const resetOptionBackgrounds = () => {
        if (opt1ref.current) opt1ref.current.style.backgroundColor = "#E7E3BD";
        if (opt2ref.current) opt2ref.current.style.backgroundColor = "#E7E3BD";
        if (opt3ref.current) opt3ref.current.style.backgroundColor = "#E7E3BD";
        if (opt4ref.current) opt4ref.current.style.backgroundColor = "#E7E3BD";
      };
      const resetOptionBackgrounds2 = () => {
        if (opt1ref.current) opt1ref.current.style.backgroundColor = "#E7E3BD";
        if (opt2ref.current) opt2ref.current.style.backgroundColor = "#E7E3BD";
        if (opt3ref.current) opt3ref.current.style.backgroundColor = "#E7E3BD";
        if (opt4ref.current) opt4ref.current.style.backgroundColor = "#E7E3BD";
        if (opt4ref.current) opt4ref.current.style.color = "black";

      };
      
      
      const updateContent2 = () => {
        resetOptionBackgrounds2(); // Reset backgrounds before updating
      if(currentlevel==='easy'){
        if (counter === 1) {
          settopanime(tutorial);
          setPlaying(geometryintro);
          setSounding(geosound2);
          playAnimationAndSound();
          setCounter(counter + 1);
        } else if (counter === 2) {
          settopanime(tutorial);
          setPlaying(geo2about);
          setSounding(geosound22);
          playAnimationAndSound();
          setCounter(counter + 1);
        } else if (counter === 3) {
          setnavigation(false);
          settopanime(questionbar);
          setPlaying(geoqn1);
          setbottomtitle("What Shape is this?");
          setSounding(geosound3);
          playAnimationAndSound();
          setCounter(counter + 1);
        } else if (counter === 4) {
          setoption1("cube");
          setoption2("Oval");
          setoption3("Triangle");
          setoption4("I dont Know");
      
          setnavigation(false);
          settopanime(questionbar);
          setPlaying(geoqn2);
          setbottomtitle("What Shape is this?");
          setSounding(geosound3);
          playAnimationAndSound();
          setCounter(counter + 1);
        }
        else if (counter === 5) {
          setoption1("cone");
          setoption2("cube");
          setoption3("icecream");
          setoption4("I dont Know");
      
          setnavigation(false);
          settopanime(questionbar);
          setPlaying(geocone);
          setbottomtitle("What Shape is this?");
          setSounding(geosound3);
          playAnimationAndSound();
          setCounter(counter + 1);
        }
        else if(counter === 6){
          <Modal show={true} centered>
          <Modal.Header>
            <Modal.Title>End of Exercise</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>More lessons will be available soon.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleRepeat}>
              Repeat
            </Button>
          </Modal.Footer>
        </Modal>
        }
      }
      else if(currentlevel==='hard'){
        if (counter === 1) {
          settopanime(questionbar);
          setPlaying(hexa);
         // setSounding(geosound22);
          playAnimationAndSound();
          setCounter(counter + 1);
        }
       else if (counter === 2) {
        settopanime(questionbar);
        setPlaying(trapezium);
        setoption1("rectangle");
        setoption2("square");
        setoption4("rhombus");
        setoption3("trapezium");
        setnavigation(false);
       // setSounding(geosound22);
        playAnimationAndSound();
        setCounter(counter + 1);
        }
        //addhere
      
      }
      else if(counter===3){
        settopanime(questionbar);

        setoption1("rectangle");
        setoption2("square");
        setoption3("rhombus");
        setoption4("trapezium");
      }
       
      };
      
      const hardlevel = () => {

        if (audioref.current) {

          audioref.current.addEventListener("ended", () => {
            audioref.current.currentTime = 0;
            audioref.current.play();
            audioref.current.volume = 1;

          });
        }
        
        setcurrentlevel('hard');
        setnavigation(false);
        setprogressvalue(0);
        setsubtopicno('Level 2');
        settopanime(questionbar);
        setPlaying(pent1);
        setCounter(1);
        resetOptionBackgrounds(); // Reset backgrounds before updating
        setoption1("Octagon");
        setoption2("Hexagon");
        setoption3("Decagon");
        setoption4("Pentagon");
        if (counter === 1) {
          setoption1("Octagon");
          setoption2("Hexagon");
          setoption3("Decagon");
          setoption4("Pentagon");
        } else if (counter === 2) {
          
          setoption1("rectangle");
          setoption2("square");
          setoption3("rhombus");
          setoption4("trapezium");
        } 
        else if (counter ===3){
          setoption1("rectangle");
          setoption2("square");
          setoption3("rhombus");
          setoption4("trapezium");
        }
      };
      

      const playnarrator = () => {
        setTimeout(() => {
          if (animationRef.current) {
            animationRef.current.play();
          }
        }, 500);
      };
      const squareStyle = {
        width: '90px',
        height: '65px',
        backgroundColor: 'skyblue',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'50px',
      };
      const squareStyle2 = {
        width: '50px',
        height: '45px',
        backgroundColor: 'skyblue',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'20px',
      };
      const scoreStyle = {
        fontSize: '15px',
        fontWeight: 'bold',
        color: 'black',
        width:'50px',
        height:'100px'
      };
      const scoreStyle2 = {
        fontSize: '10px',
        fontWeight: 'bold',
        color: 'black',
        width:'40px',
        height:'70px'
      };
      const handleAudioLoad = () => {
        if (audioref2.current) {
          if (counter > 1) {
            audioref2.current.play().catch((error) => {
              console.error("Audio play interrupted:", error);
            });
          }
        }
      };
      const handleAudioLoad2 = () => {
        if (audioref.current) {
      
            audioref.current.play().catch((error) => {
              console.error("Audio play interrupted:", error);
            });
         
        }
      };
      const handleAnswer = (selectedRef) => {
        if(currentlevel==='easy'){
          if (counter === 4) {
            if (selectedRef === opt1ref.current) {
              handleCorrectAnswer(selectedRef);
            } else {
              handleWrongAnswer(selectedRef);
            }
          }
          else if (counter === 5) {
            if (selectedRef === opt3ref.current) {
              handleCorrectAnswer(selectedRef);
            } else {
              handleWrongAnswer(selectedRef);
            }
          }
          else if (counter === 6) {
            if (selectedRef === opt1ref.current) {
              handleCorrectAnswer(selectedRef);
            } else {
              handleWrongAnswer(selectedRef);
            }
          }
          else {
            handleWrongAnswer(selectedRef);
          }
        }
        else if (currentlevel==='hard') {
          if (counter === 1) {
            if (selectedRef === opt4ref.current) {
              setPlaying(correctpent)
              handleCorrectAnswer(selectedRef);
              resetOptionBackgrounds();
            }
            
            else {
              setPlaying(wrongpent)

              handleWrongAnswer(selectedRef);
            }
          }
          else if (counter === 2) {
            if (selectedRef === opt2ref.current) {
              setPlaying(correcthex)

              handleCorrectAnswer(selectedRef);

              resetOptionBackgrounds();

            } else {
              handleWrongAnswer(selectedRef);
            }
          }
          else if (counter === 3){
            if (selectedRef === opt3ref.current) {
            
              setPlaying(trapc)

              handleCorrectAnswer(selectedRef);
  
              resetOptionBackgrounds2();
            }
           
          }
        }
   
      };
      
      
      const handleCorrectAnswer = (selectedRef) => {

        if(currentlevel==='easy'){
          playcorrectsound();

          if (hadbeenwrong) {
            if(counter===5){
              settopanime(tutorial);
              setnextlevel(true);
              setPlaying(geosamo)
  
            }
            else if (counter===6){
              setPlaying(conecorrect2);

            }
            else{
              setPlaying(correct1b);
  
            }
          } else {
            if(counter===4){
              setPlaying(correct1);
  
            }
            else if(counter===5){
  settopanime(tutorial);
                setPlaying(geosamo);
  
             
            }
            else if (counter === 6 ){
              settopanime(tutorial);
              setPlaying(conecorrect1);

            }
          }
          
        }
       else{
        settopanime(tutorial);

       }
        setTimeout(() => {
          gsap.to(opt1ref, { backgroundColor: '#715CFE', duration: 1, delay: 0.5, ease: 'power2.inOut' });
          gsap.to(opt2ref, { backgroundColor: '#715CFE', duration: 1, delay: 0.5, ease: 'power2.inOut' });
          gsap.to(opt3ref, { backgroundColor: '#715CFE', duration: 1, delay: 0.5, ease: 'power2.inOut' });
          gsap.to(opt4ref, { backgroundColor: '#715CFE', duration: 1, delay: 0.5, ease: 'power2.inOut' });
         
        }, 1000);
        playAnimationAndSound();
        // Animate the correct option
        gsap.to(selectedRef, { backgroundColor: 'green', duration: 1, delay: 0.5, ease: 'power2.inOut' });
        gsap.to(selectedRef, { backgroundColor: '#E7E3BD', duration: 1, delay: 2, ease: 'power2.inOut' });

        setnavigation(true);
        console.log("✅ Correct Answer!");
        setSounding(correctsound);
        setscorevalue(scorevalue + 1);
        // setprogressvalue(progressvalue + 10);
      };
      
      const handleWrongAnswer = (selectedRef) => {
        if(currentlevel ==='easy'){
          if(counter===5){
            setPlaying(wrong1);
  
          }
          else if(counter===6){
            setPlaying(conewrong);
  
          }
        }
        else if(currentlevel === 'hard') {
          if(counter===5){
            setPlaying(wrong1);
  
          }
          else if(counter===6){
            setPlaying(wrong2);
  
          }
        }
       
        playAnimationAndSound();

        playwrongsound();
        sethadbeenwrong(true);
        console.log("❌ Wrong Answer!");
        setSounding(wrongsound);
      
        // Animate the wrong option
        gsap.to(selectedRef, { backgroundColor: 'red', duration: 1, delay: 0.2, ease: 'power2.inOut' });
      };
      
      
  return (
    <>
        {isMobile? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Card style={{ width: "500px" }}>
            <Card.Header style={{height:'80px'}}>
            <div style={{ display: 'flex', fontFamily:'helvetica',fontWeight:'bold' }}>
             <div style={{fontSize:'15px',marginTop:'10px', marginLeft:'10px'}}>
              {subtopicno}
             </div>
             <div style={{fontSize:'10px',marginTop:'10px', marginLeft:'15px',}}>
              {currentlevel}
             </div>
             
             
             <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
             <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>
        
          <div style={{ width: '200px',marginTop:'15px',marginLeft:'5px' }}> 
          <ProgressBar progress={progressvalue} label="Completed"/>
        
        </div>
            
           
            <div style={{alignItems:'center',}}>
               <div style={squareStyle2}>
               <div style={scoreStyle2}>Score</div>
               <div style={scoreStyle2}>{scorevalue}</div>
             </div>
             </div>
        
           
             </div>
             
              {/* New Row with Text Options */}
            
          
              {/* Original Row with Equations */}
              
          
          
            </div>
          </Card.Header>     
          <Card.Body
      
       style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#f8f9fa",
          position: "relative",
          pointerEvents: "auto"
        }}
      >
      
      <>
        {showPrompt ? (
          <div className="end-prompt">
            <h2>End of Exercise</h2>
            <h4>Updates will be available soon </h4>
            <div className="buttons">
              <button onClick={() => window.location.href = "/physicsp1"}>Try Physics</button>
              <button onClick={() => window.location.href = "/geometry"}>Repeat exercise</button>
              <button onClick={() => window.location.href = "/subjectselect"}>Home</button>

            </div>
      
            {/* Rating Component */}
            <div className="rating">
              <p>Rate this exercise:</p>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color: star <= rating ? "gold" : "gray",
                  }}
                  onClick={() => setRating(star)}
                >
                  ★
                </span>
              ))}
            </div>
          </div>
        ) : (
          <div>
            {currentlevel === "easy" ? (
              <>
                {topanime === questionbar && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridTemplateRows: "1fr 1fr",
                      gap: "15px",
                      position: "absolute",
                      fontWeight: "bold",
                      fontFamily: "Helvetica",
                      top: "20%",
                      left: "30%",
                      zIndex: 5,
                      transform: "translate(-50%, -50%)",
                      pointerEvents: "auto"
                    }}
                  >
                    {/* Answer Options */}
                    {[option1, option2, option3, option4].map((option, index) => (
                      <div
                        key={index}
                        ref={[opt1ref, opt2ref, opt3ref, opt4ref][index]}
                        style={{
                          cursor: "pointer",
                          backgroundColor: index === 3 ? "#715CFE" : "#E7E3BD",
                          borderRadius: "10px",
                          padding: "10px 20px",
                          textAlign: "center",
                          color: index === 3 ? "white" : "black",
                        }}
                        onClick={() => handleAnswer([opt1ref, opt2ref, opt3ref, opt4ref][index].current)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <>
                {topanime === questionbar && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridTemplateRows: "1fr 1fr",
                      gap: "15px",
                      position: "absolute",
                      fontWeight: "bold",
                      fontFamily: "Helvetica",
                      top: "10%",
                      left: "30%",
                      zIndex: 5,
                      transform: "translate(-50%, -50%)",
                      pointerEvents: "auto"
                    }}
                  >
                    {/* Answer Options */}
                    {[option1, option2, option3, option4].map((option, index) => (
                      <div
                        key={index}
                        ref={[opt1ref, opt2ref, opt3ref, opt4ref][index]}
                        style={{
                          cursor: "pointer",
                          backgroundColor: index === 3 ? "#715CFE" : "#E7E3BD",
                          borderRadius: "10px",
                          padding: "10px 20px",
                          textAlign: "center",
                          color: index === 3 ? "white" : "black",
                        }}
                        onClick={() => handleAnswer([opt1ref, opt2ref, opt3ref, opt4ref][index].current)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
      
            {/* Lottie Animation */}
            <div ref={playref} style={{ marginTop: "10px", zIndex: 1 }} className="math-learning">
              <Player
                ref={animationRef}
                autoplay={false}
                loop={false}
                keepLastFrame={true}
                src={playing}
                onLoadedData={handleanimationplay}
                className={isMobile ? "mobile-animation" : "desktop-animation"}
                style={{ height: "300px", width: "400px" }}
              />
            </div>
          </div>
        )}
      </>
      
      </Card.Body>
      
              <Card.Footer>
              <div>
            {navigation ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <div>
                <CircularButtonback onClick={goback} />
              </div>
              {nextlevel? (
                <div onClick={hardlevel} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <Player
                  ref={rptref}
                  autoplay={false}
                  loop={false}
                  keepLastFrame={true}
                  hover={true}
                  src={tooeasy}
                  style={{ width: '100px', height: '70px' }}
                />
              </div>
              ): (
      <div onClick={repeatanimation} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <Player
                  ref={rptref}
                  autoplay={false}
                  loop={false}
                  keepLastFrame={true}
                  hover={true}
                  src={rpt}
                  style={{ width: '100px', height: '70px' }}
                />
              </div>
              )}
              
              <div>
                <CircularButton className='ml-auto' onClick={next} />
              </div>
            </div>
          ) : 
          
            (
          
          
             <div style={{  alignItems:'center'}}>
                        <>
                        {topanime===questionbar? (
                           <div 
                           ref={startref}
                           onClick={playAnimationAndSound} 
                           style={{ 
                             cursor: 'pointer',
                            // backgroundColor: 'grey',
                             borderRadius: '10px',
                             width: '300px',
                             height: '50px',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             color: 'red',
                             fontWeight: 'bold',
                             fontSize: '18px',
                             marginTop:'10px'
                           }}
                         >
      {                     bottomtitle
      }                   </div>
                        ):(
        <div onClick={playAnimationAndSound2} style={{ cursor: 'pointer' }}>
                          <Player
                            ref={startref}
                            autoplay={true}
                            loop={true}
                            keepLastFrame={true}
                            src={bottomanime}
                            style={{ width: '300px', height: '100px' }}
                          />
                        </div> 
                        )} 
                                        </>
        
          <div>
           
         
          </div>
                        </div>
          
            
          
            )}
            </div>
              </Card.Footer>
            </Card>
            <div>
            <Player>
              <audio autoplay={true} ref={audioref2} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
            </Player>
          </div>
          <div>
        <audio autoPlay loop ref={audioref} src={hardbgsound} type="audio/mp3" onLoadedData={handleAudioLoad2} />
      </div>
      
          <div>
            <Player>
              <audio autoplay={true} ref={audioref3} src={notification} type="audio/wav" onLoadedData={handleAudioLoad} />
            </Player>
          </div>
          </div>
        ):(
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
  <Card style={{ width: "500px" }}>
  <Card.Header style={{height:'80px'}}>
  <div style={{ display: 'flex', fontFamily:'helvetica',fontWeight:'bold' }}>
   <div style={{fontSize:'15px',marginTop:'10px', marginLeft:'10px'}}>
    {subtopicno}
   </div>
   <div style={{fontSize:'10px',marginTop:'10px', marginLeft:'15px',}}>
    {currentlevel}
   </div>
   
   
   <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
   <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>

<div style={{ width: '200px',marginTop:'15px',marginLeft:'5px' }}> 
<ProgressBar progress={progressvalue} label="Completed"/>

</div>
  
 
  <div style={{alignItems:'center',}}>
     <div style={squareStyle}>
     <div style={scoreStyle}>Score</div>
     <div style={scoreStyle}>{scorevalue}</div>
   </div>
   </div>

 
   </div>
   
    {/* New Row with Text Options */}
  

    {/* Original Row with Equations */}
    


  </div>
</Card.Header>     
<Card.Body

style={{
height: "500px",
display: "flex",
justifyContent: "center",
alignItems: "center",
background: "#f8f9fa",
position: "relative",
pointerEvents: "auto"
}}
>

<>
{showPrompt ? (
<div className="end-prompt">
  <h2>End of Exercise</h2>
  <h4>Updates will be available soon </h4>
  <div className="buttons">
    <button onClick={() => window.location.href = "/mlearn"}>Try Algebra</button>
    <button onClick={() => window.location.href = "/geometry"}>Repeat exercise</button>
  </div>

  {/* Rating Component */}
  <div className="rating">
    <p>Rate this exercise:</p>
    {[1, 2, 3, 4, 5].map((star) => (
      <span
        key={star}
        style={{
          fontSize: "24px",
          cursor: "pointer",
          color: star <= rating ? "gold" : "gray",
        }}
        onClick={() => setRating(star)}
      >
        ★
      </span>
    ))}
  </div>
</div>
) : (
<div>
  {currentlevel === "easy" ? (
    <>
      {topanime === questionbar && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            gap: "15px",
            position: "absolute",
            fontWeight: "bold",
            fontFamily: "Helvetica",
            top: "20%",
            left: "30%",
            zIndex: 5,
            transform: "translate(-50%, -50%)",
            pointerEvents: "auto"
          }}
        >
          {/* Answer Options */}
          {[option1, option2, option3, option4].map((option, index) => (
            <div
              key={index}
              ref={[opt1ref, opt2ref, opt3ref, opt4ref][index]}
              style={{
                cursor: "pointer",
                backgroundColor: index === 3 ? "#715CFE" : "#E7E3BD",
                borderRadius: "10px",
                padding: "10px 20px",
                textAlign: "center",
                color: index === 3 ? "white" : "black",
              }}
              onClick={() => handleAnswer([opt1ref, opt2ref, opt3ref, opt4ref][index].current)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </>
  ) : (
    <>
      {topanime === questionbar && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            gap: "15px",
            position: "absolute",
            fontWeight: "bold",
            fontFamily: "Helvetica",
            top: "10%",
            left: "30%",
            zIndex: 5,
            transform: "translate(-50%, -50%)",
            pointerEvents: "auto"
          }}
        >
          {/* Answer Options */}
          {[option1, option2, option3, option4].map((option, index) => (
            <div
              key={index}
              ref={[opt1ref, opt2ref, opt3ref, opt4ref][index]}
              style={{
                cursor: "pointer",
                backgroundColor: index === 3 ? "#715CFE" : "#E7E3BD",
                borderRadius: "10px",
                padding: "10px 20px",
                textAlign: "center",
                color: index === 3 ? "white" : "black",
              }}
              onClick={() => handleAnswer([opt1ref, opt2ref, opt3ref, opt4ref][index].current)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </>
  )}

  {/* Lottie Animation */}
  <div ref={playref} style={{ marginTop: "10px", zIndex: 1 }} className="math-learning">
    <Player
      ref={animationRef}
      autoplay={false}
      loop={false}
      keepLastFrame={true}
      src={playing}
      onLoadedData={handleanimationplay}
      className={isMobile ? "mobile-animation" : "desktop-animation"}
      style={{ height: "300px", width: "400px" }}
    />
  </div>
</div>
)}
</>

</Card.Body>

    <Card.Footer>
    <div>
  {navigation ? (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <div>
      <CircularButtonback onClick={goback} />
    </div>
    {nextlevel? (
      <div onClick={hardlevel} style={{ cursor: 'pointer', textAlign: 'center' }}>
      <Player
        ref={rptref}
        autoplay={false}
        loop={false}
        keepLastFrame={true}
        hover={true}
        src={tooeasy}
        style={{ width: '100px', height: '70px' }}
      />
    </div>
    ): (
<div onClick={repeatanimation} style={{ cursor: 'pointer', textAlign: 'center' }}>
      <Player
        ref={rptref}
        autoplay={false}
        loop={false}
        keepLastFrame={true}
        hover={true}
        src={rpt}
        style={{ width: '100px', height: '70px' }}
      />
    </div>
    )}
    
    <div>
      <CircularButton className='ml-auto' onClick={next} />
    </div>
  </div>
) : 

  (


   <div style={{  alignItems:'center'}}>
              <>
              {topanime===questionbar? (
                 <div 
                 ref={startref}
                 onClick={playAnimationAndSound} 
                 style={{ 
                   cursor: 'pointer',
                  // backgroundColor: 'grey',
                   borderRadius: '10px',
                   width: '300px',
                   height: '50px',
                   display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   color: 'red',
                   fontWeight: 'bold',
                   fontSize: '18px',
                   marginTop:'10px'
                 }}
               >
{                     bottomtitle
}                   </div>
              ):(
<div onClick={playAnimationAndSound2} style={{ cursor: 'pointer' }}>
                <Player
                  ref={startref}
                  autoplay={true}
                  loop={true}
                  keepLastFrame={true}
                  src={bottomanime}
                  style={{ width: '300px', height: '100px' }}
                />
              </div> 
              )} 
                              </>

<div>
 

</div>
              </div>

  

  )}
  </div>
    </Card.Footer>
  </Card>
  <div>
  <Player>
    <audio autoplay={true} ref={audioref2} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
  </Player>
</div>
<div>
<audio autoPlay loop ref={audioref} src={hardbgsound} type="audio/mp3" onLoadedData={handleAudioLoad2} />
</div>

<div>
  <Player>
    <audio autoplay={true} ref={audioref3} src={notification} type="audio/wav" onLoadedData={handleAudioLoad} />
  </Player>
</div>
</div>
        )}
        
  )}

    </>

  );
};

export default Geometry;