import React, { useEffect, useRef, useState } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { Player } from '@lottiefiles/react-lottie-player';
import SubjectSelectemp from "./Temps/SubjectSelectemp";
import Physicsp1 from "./UNEB/Physicsp1";
import sci1 from './jsons/intro/sc.json';
import frustrated from './jsons/fear.json';
import Subtopicstemp from "./Temps/Subtopicstemp";
import electricity1 from './jsons/experiment assets/electricity1.json';
import idea from './jsons/idea.json';
import Cprac1 from './Chemix/Cprac1';
import Sim2 from './Experiments/Sim6';
import Sim3 from "./Experiments/Sim3";
import Sim4 from "./Experiments/Sim4";
import Sim5 from "./Experiments/Sim5";
import { Bar } from 'react-chartjs-2';
import electricsound from './jsons/sounds/electricity.mp3';
import bgsound from './jsons/sounds/electricity.mp3';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

const PreviewPage = () => {
  const [counter, setCounter] = useState(1);
  const [text, setText] = useState("Learn, Experiment & discover science");
  const [title, setTitle] = useState("Welcome to Scisteps");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [anim, setanim] = useState(true);
  const [holder, setholder] = useState(sci1);
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [dataFromChild, setDataFromChild] = useState('');

  const animeref = useRef(null);
  const audioRef = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const buttonsref = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      if (counter === 6) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset audio when stopping
      }
    }
  }, [counter]);
  
    
  useEffect(() => {
    gsap.set([section1Ref.current, section2Ref.current,buttonsref.current], { 
      opacity: 0, 
      y: +50 // Start 50px above their current position 
    });
  
    gsap.to(section1Ref.current, {
      duration: 1.7,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 0.3,
    });

    gsap.to(section2Ref.current, {
      duration: 1.5,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 1.5,
    });
    gsap.to(buttonsref.current, {
      duration: 1.7,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power4.inOut",
      delay: 1,
    });
  }, []);
  
  
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    if(data){
        setCurrentComponentIndex((prevIndex) => (prevIndex + 1) % components.length);

    }
   
  };
  const components = [<Sim2 sendDataToParent={handleDataFromChild} />, <Sim3 sendDataToParent={handleDataFromChild} />, <Sim4 sendDataToParent={handleDataFromChild}/>, <Sim5 sendDataToParent={handleDataFromChild}/> , ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Prevents shrinking on re-renders
    plugins: {
      title: {
        display: true,
        text: 'Your Performance',
      },
    },
  };
  const PerformanceChart = ({ show }) => {
    return show ? (
      <div style={{ width: '100%', height: '300px' }}>
        <Bar data={data} options={options} />
      </div>
    ) : null;
  };
  const colorPalette = {
    primary: '#84250C',
    secondary: '#3C393E',
    light: '#F5F4EB'
  };

  const handleNext = () => {
    setCounter((prevCounter) => {
      const newCounter = prevCounter + 1;
      switch (newCounter) {
        case 2:
          setText("The fundamentals in sciences");
          setTitle("What will you learn from Scisteps?");
          setanim(false);
          break;
        case 3:
          setText("  ");
          setTitle("With a wide range of topics and subtopics");
          break;
        case 4:
          setTitle("But Research tells us that");
          setText("Students struggle to understand written science");
          setanim(true);
          setholder(frustrated);
          break;
        case 5:
          setholder(idea);
          setTitle("So we thought of an idea");
          setText("To make science learning easy and fun for students");
          break;
        case 6:
          setholder(electricity1);
          setTitle("We created Animated Lessons for Sciences");
          setText("To keep students' attention and help them understand concepts that seem complex");
          setanim(true);
          break;
        case 7:
          setanim(false);
          setTitle("Virtual Experiments for students");
          setText("Move the sodium (black) into the water");
          break;
        case 8:
          setanim(false);
          setTitle("Tests");
          setText("Center of gravity, which one is more stable?");
          break;
          case 9:
            setTitle("Performance Analytics");
            setText("Get an indepth analysis on students on an individual and group level");
            break;
            case 10:
  setanim(false);
  setText("click one of the buttons");
  setTitle("Read about us, or start scisteps   ");
  break;
        default:
          setText("click one of the buttons");
          setTitle("Read about us, or start scisteps   ");
          break;
      }
      return newCounter;
    });
  };
  const data = {
    labels: ['Math', 'Physics', 'Chemistry'], // Subjects
    datasets: [
      {
        label: 'Performance (%)',
        data: [85, 65, 40], // Sample scores for the subjects
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',  // Math - Red
          'rgba(54, 162, 235, 0.6)',  // Physics - Blue
          'rgba(255, 206, 86, 0.6)'   // Chemistry - Yellow
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };
  
  const handleprevious = () => {
    setCounter((prevCounter) => {
      if (prevCounter <= 1) return prevCounter; // Prevent counter from going below 1
      const newCounter = prevCounter - 1;
  
      switch (newCounter) {
        case 1:
          setanim(true);
          setholder(sci1);
          setText("Learn, Experiment & discover Science");
          setTitle("Welcome to scisteps");
          break;
        case 2:
          setText("The fundamentals in sciences");
          setTitle("What will you learn from Scisteps?");
          setanim(false);
          break;
        case 3:
          setanim(false);
          setText(" ");
          setTitle("With a wide range of topics and subtopics");
          break;
        case 4:
          setTitle("But Research tells us that");
          setText("Students struggle to understand written science");
          setanim(true);
          setholder(frustrated);
          break;
        case 5:
          setholder(idea);
          setTitle("So we thought of an idea");
          setText("To make science learning easy and fun for students");
          break;
        case 6:
          setholder(electricity1);
          setTitle("We created Animated Lessons for Sciences");
          setText("To keep students' attention and help them understand concepts that seem complex ");
          setanim(true);
          break;
        case 7:
          setanim(false);
          setTitle("Virtual Experiments for students");
          setText("Move the sodium (black) into the water");
          break;
        case 8:
          setanim(false);
          setTitle("Tests");
          setText("Test: center of gravity, select which is more stable");
          break;
case 10:
  setanim(false);
  setText("click one of the buttons");
  setTitle("Read about us, or start scisteps   ");
  break;
        default:
          setanim(false);
          setText("click one of the buttons");
          setTitle("Read about us, or start scisteps   ");
          break;
      }
      return newCounter;
    });
  };
  
  
  return (
    <Container fluid className="d-flex flex-column justify-content-center align-items-center vh-100">
      <Card  ref={section1Ref} >
        <Card.Header style={{fontWeight:'bold',backgroundColor:colorPalette.primary,color:'white'}} className="text-center border-bottom border-dark">{title}</Card.Header>
        <Card.Body 
  className="d-flex flex-column justify-content-center align-items-center text-center"
  style={{ minHeight: "60vh" }} // Ensures full viewport height for vertical centering
>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div className="flex-grow-1 d-flex justify-content-center align-items-center" style={{ minHeight: "100px",maxHeight: isMobile? '800px': '500px' }}>
      <>
        {anim ? (
          <div>
            <Player
              ref={animeref}
              autoplay={true}
              loop={true}
              src={holder}
              className={isMobile ? 'mobile-animation' : 'desktop-animation'}
              style={{ height: '400px' }}
            />
            <audio ref={audioRef} src={electricsound}/>

          </div>
        ) : (
          <div>
            {counter === 2 && (
              <div style={{scale:0.3}}>
                <SubjectSelectemp />
              </div>
            )}
            {
                counter === 3 && (
                    <div style={{scale:0.5}}>
                    <Subtopicstemp/>

                        </div>
                )
            }
            {counter === 7 && (
                <Cprac1 style={{ width: '380px', height: '140px' ,position:'fixed',top:'10px' }} />

            )
        }
           {counter === 8 && (
 <div
 className="pe-3 border-end"
 style={{ width: '400px', height: '250px' }}
>
{components[currentComponentIndex]}
</div>
            )
        }
   {counter === 9 && <PerformanceChart show={true} />}


 {counter >= 10 && (

<div style={{display:'flex',padding:'15px',gap: '20px'}}>
<Link to='/subjectselect' style={{ textDecoration: 'none', marginTop: '10px' }}>

  <Button style={{backgroundColor:colorPalette.primary}}>
Start Scisteps
  </Button>
  </Link>
  <Link to='/about' style={{ textDecoration: 'none', marginTop: '10px' }}>
  <Button>
Read about us
  </Button>
  </Link>

  </div>
            )
        }
          </div>
        )}
      </>
    </div>
  </div>
</Card.Body>

        <Card.Footer className="text-center border-top border-dark">{text}</Card.Footer>
      </Card>
      <div ref={section2Ref} className="d-flex justify-content-between align-items-center w-100 p-3 position-absolute bottom-0">
  <Button variant="secondary" onClick={handleprevious}>Previous</Button>
  <span style={{ fontSize: '1.2rem', fontWeight: 'bold', }}>{counter}/10</span>
  <div className="d-flex gap-2">  
    <Button variant="primary" onClick={handleNext}>Next</Button>
    <Button 
  variant="warning" 
  onClick={() => { 
    setCounter(10); 
    setanim(false);  
    setText("Click one of the buttons"); 
    setTitle("Read about us, or start Scisteps"); 
  }}
>
  Skip Preview
</Button>
  </div>
</div>


    </Container>
  );
};

export default PreviewPage;