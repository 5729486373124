import React, { useState, useEffect, useRef } from "react";
import { useDrag } from "react-use-gesture";
import { gsap } from "gsap";

const PlotGraph2 = ({ rulerData }) => {
  const [draggedPosition, setDraggedPosition] = useState({ x: 0, y: 0 });
  const [highlightedPoints, setHighlightedPoints] = useState({ x: null, y: null });

  const shapeRef = useRef(null);

  // Drag handler
  const bind = useDrag(({ offset: [dx, dy] }) => {
    setDraggedPosition({ x: dx, y: dy });

    // Find closest x and y values on the ruler
    const closestX = rulerData.x.reduce((prev, curr) =>
      Math.abs(curr.x - dx) < Math.abs(prev.x - dx) ? curr : prev
    );
    const closestY = rulerData.y.reduce((prev, curr) =>
      Math.abs(curr.y - dy) < Math.abs(prev.y - dy) ? curr : prev
    );

    setHighlightedPoints({ x: closestX.label, y: closestY.label });
  });

  return (
    <div className="plot-container">
      {/* X-axis Labels */}
      <div className="x-axis">
        {rulerData.x.map(({ x, label }) => (
          <span
            key={x}
            style={{
              color: highlightedPoints.x === label ? "red" : "black",
            }}
          >
            {label}
          </span>
        ))}
      </div>

      {/* Y-axis Labels */}
      <div className="y-axis">
        {rulerData.y.map(({ y, label }) => (
          <span
            key={y}
            style={{
              color: highlightedPoints.y === label ? "red" : "black",
            }}
          >
            {label}
          </span>
        ))}
      </div>

      {/* Draggable Shape */}
      <div
        {...bind()}
        ref={shapeRef}
        style={{
          width: "50px",
          height: "50px",
          background: "blue",
          position: "absolute",
          left: draggedPosition.x,
          top: draggedPosition.y,
          cursor: "grab",
        }}
      />
    </div>
  );
};

export default PlotGraph2;
