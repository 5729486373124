import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import gsap from "gsap";
import Draggable from "gsap/Draggable";

const PlotGraph2 = () => {
  const svgRef = useRef(null);
  const [shapes, setShapes] = useState([]);
  const [highlightedPoint, setHighlightedPoint] = useState(null);

  useEffect(() => {
    gsap.registerPlugin(Draggable);

    const width = 600;
    const height = 400;
    const margin = 40;

    d3.select("#graph").selectAll("*").remove();

    const svg = d3
      .select("#graph")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .style("border", "1px solid black")
      .attr("id", "main-svg");

    svgRef.current = svg.node();

    const xScale = d3.scaleLinear().domain([-10, 10]).range([margin, width - margin]);
    const yScale = d3.scaleLinear().domain([-10, 10]).range([height - margin, margin]);

    // X and Y Axes
    const xAxis = svg.append("g")
      .attr("transform", `translate(0, ${yScale(0)})`)
      .call(d3.axisBottom(xScale));

    const yAxis = svg.append("g")
      .attr("transform", `translate(${xScale(0)}, 0)`)
      .call(d3.axisLeft(yScale));

    svg.append("g").attr("class", "highlight-group");

  }, []);

  // Function to highlight axis values
  const highlightAxisValues = (x, y) => {
    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);
    const xScale = d3.scaleLinear().domain([-10, 10]).range([40, 560]);
    const yScale = d3.scaleLinear().domain([-10, 10]).range([360, 40]);

    setHighlightedPoint({ x, y });

    // Remove previous highlights
    svg.selectAll(".highlight-text").remove();

    // Add new highlighted axis values
    svg.append("text")
      .attr("x", xScale(x))
      .attr("y", 385)
      .attr("text-anchor", "middle")
      .attr("class", "highlight-text")
      .style("fill", "red")
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .text(x.toFixed(1));

    svg.append("text")
      .attr("x", 20)
      .attr("y", yScale(y) + 5)
      .attr("text-anchor", "middle")
      .attr("class", "highlight-text")
      .style("fill", "red")
      .style("font-size", "16px")
      .style("font-weight", "bold")
      .text(y.toFixed(1));
  };

  // Function to create new draggable shapes
  const addShape = () => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const xScale = d3.scaleLinear().domain([-10, 10]).range([40, 560]);
    const yScale = d3.scaleLinear().domain([-10, 10]).range([360, 40]);

    const newShape = {
      id: shapes.length,
      type: Math.random() > 0.5 ? "circle" : "square",
      x: Math.random() * 20 - 10,
      y: Math.random() * 20 - 10,
    };

    setShapes([...shapes, newShape]);

    if (newShape.type === "circle") {
      svg.append("circle")
        .attr("cx", xScale(newShape.x))
        .attr("cy", yScale(newShape.y))
        .attr("r", 15)
        .attr("fill", "green")
        .attr("class", `draggable-shape shape-${newShape.id}`);
    } else {
      svg.append("rect")
        .attr("x", xScale(newShape.x) - 15)
        .attr("y", yScale(newShape.y) - 15)
        .attr("width", 30)
        .attr("height", 30)
        .attr("fill", "purple")
        .attr("class", `draggable-shape shape-${newShape.id}`);
    }

    setTimeout(() => {
      Draggable.create(`.shape-${newShape.id}`, {
        type: "x,y",
        bounds: "#main-svg",
        onDragEnd: function () {
          const draggedX = xScale.invert(parseFloat(this.x));
          const draggedY = yScale.invert(parseFloat(this.y));
          highlightAxisValues(draggedX, draggedY);
        }
      });
    }, 100);
  };

  return (
    <div>
      <button onClick={addShape}>Create New Shape</button>
      <div id="graph"></div>
    </div>
  );
};

export default PlotGraph2;
