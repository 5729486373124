
import React, { useEffect, useRef, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import chemicons from '../jsons/chemicons.json';
import { Player } from '@lottiefiles/react-lottie-player';
import physicsicon from '../jsons/phyicons2.json';
import mtcicons from '../jsons/mtcicons.json';
import cprac from '../jsons/cpracicons.json';
import portrait from '../jsons/portraitbg.json';
import mathintro from '../jsons/mathintro.json';
import background1 from '../jsons/images/pendu2.jpg';
import background2 from '../jsons/images/phys.jpg';
import background3 from '../jsons/images/phy6.jpg';
import mtch1 from '../jsons/images/mtc H1.jpg';
import chem from '../jsons/images/chemi.jpg';
import mtc1 from '../jsons/images/mtc_1.jpg';
import phy8 from '../jsons/images/phys.jpg';
import pendu from '../jsons/images/pendu.jpg';
import phy6 from '../jsons/images/phy6.jpg';
import { gsap } from 'gsap';

const SubjectSelectemp = () => {
  const animationRef = useRef(null);
  const introref = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const bgRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    gsap.set([section1Ref.current, section2Ref.current,section3Ref.current], { 
      opacity: 0, 
      y: +50 // Start 50px above their current position 
    });
  
    gsap.to(section1Ref.current, {
      duration: 1.3,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 0.3,
    });

    gsap.to(section2Ref.current, {
      duration: 1.3,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 0.6,
    });
    gsap.to(section3Ref.current, {
      duration: 1.3,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power4.inOut",
      delay: 0.9,
    });
  }, []);
  


  return (
    <>
       
          <div>
          {/* Header */}
    
  
    
    
          {/* Cards Section */}
      {/* Cards Section */}
{/* Cards Section */}
<Container className="mt-3">
  <Row className="d-flex justify-content-center">
    {/* PhysicsSteps Card */}
    <Col ref={section1Ref} md={4} className="mb-2">
      <Card className="h-100 text-center" style={{ maxHeight: '150px', overflow: 'hidden' }}>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <Link to="/subtopics" style={{ color: 'black', textDecoration: 'none', width: '100%' }}>
            <Card.Title><h6>Physics</h6></Card.Title>
            <Player
              ref={animationRef}
              src={physicsicon}
              loop={true}
              autoplay={true}
              style={{ width: '200px', height: '100px' }}
            />
          </Link>
        </Card.Body>
      </Card>
    </Col>

    {/* MathSwipe Card */}
    <Col ref={section2Ref} md={4} className="mb-2">
      <Card className="h-100 text-center" style={{ maxHeight: '150px', overflow: 'hidden' }}>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
        <a href="https://mathswipe.com" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none', width: '100%' }}>
            <Card.Title><h6>MathSwipe</h6></Card.Title>
            <Player
              ref={animationRef}
              src={mtcicons}
              loop={true}
              autoplay={true}
              style={{ width: '200px', height: '100px' }}
            />
          </a>
        </Card.Body>
      </Card>
    </Col>

    {/* Chemix Card */}
    <Col ref={section3Ref} md={4} className="mb-2">
      <Card className="h-100 text-center" style={{ maxHeight: '150px', overflow: 'hidden' }}>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <Link to="/chemistrytopics" style={{ color: 'black', textDecoration: 'none', width: '100%' }}>
            <Card.Title><h6>Chemistry</h6></Card.Title>
            <Player
              ref={animationRef}
              src={chemicons}
              loop={true}
              autoplay={true}
              style={{ width: '200px', height: '100px' }}
            />
          </Link>
        </Card.Body>
      </Card>
    </Col>
  </Row>
</Container>

      
     
        </div>
    

    </>
  
  );
};

export default SubjectSelectemp;
