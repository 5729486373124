import React, { useEffect, useRef, useState } from "react";
import { Container, Card, Button } from 'react-bootstrap';
import PlotGraph from '../Experiments/PlotGraph';
import { Player } from "@lottiefiles/react-lottie-player";
import car1 from '../jsons/car3.json';
import carplain from '../jsons/carplain.json';
import carenbuilding from '../jsons/car5.json';
import car2 from '../jsons/car24.json';
import car3 from '../jsons/car23.json';
import fireworks from '../jsons/firework.json';

const TestComp = ({ title, text, handlePrevious, handleNext, counter, setCounter, setAnim, setText, setTitle }) => {
  const animref = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [triggered, settriggered] = useState(0);
  const [carstart, setcarstart] = useState(false);
  const [checker2, setchecker2] = useState(0);
  const [checker3, setchecker3] = useState(0);
  const [holder, setholder] = useState(carenbuilding);
  const [cc, setcc] = useState(false);

  const [dataFromChild, setDataFromChild] = useState(null,null);

  const handleToggleLineRemoval = () => {
    settriggered(true); // Trigger the line removal
    setTimeout(() => {
      settriggered(false);
    }, 1000);
  };

  const handlecarstart = () => {
   setTimeout(() => {
    setcarstart(true); // Trigger the line removal

   }, 100);
   setchecker3(1);
   
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);
useEffect(() => {
 
  if(checker3===1){
    setchecker2(1)
  }
}, [checker2,checker3]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
};

  const colorPalette = {
        primary: '#84250C',
        secondary: '#3C393E',
        light: '#F5F4EB'
      };
      const handleDataFromChild = (data) => {
        setDataFromChild(data);
        if(data===1)
          {
            setholder(carenbuilding);
        }
        else if (data===2)
          {
            setholder(car2);
        }
        else if(data===3){
          setholder(car3);

        }
        else if(data===4){
          setcc(true);
        }
       
      };
    
  return (
    <Container fluid className="d-flex flex-column justify-content-center align-items-center vh-100">
      <Card>
      
        <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center" >
          {/* test components */}
      <>
      {carstart && (
 <div style={{position:'absolute',zindex: 3, backgroundColor:"white",left: isMobile ? '-92px' : '-150px',
  top: isMobile ? '-20px' : '-235px'
   }}>
 <Player
           ref={animref}
           autoplay={true}
           loop={false}
           hover={false}
           keepLastFrame={true}
           src={holder}
           aspectRatio="3:4"
           speed={0.5}
           style={{ width:isMobile?'300px': '500px', height:isMobile?'300px': '500px' }}

           backgroundColor="white"
           />
 </div>
      )}  
      {cc==true && (
        <div style={{position:'absolute'}}>

         <Player
         ref={animref}
         autoplay={true}
         loop={true}
         hover={false}
  
         src={fireworks}
         aspectRatio="3:4"
         speed={2}
         style={{ width:isMobile?'300px': '500px', height:isMobile?'300px': '500px' }}

         backgroundColor="white"
         />
                 </div>

      )}
             </>

<div style={{zindex: 1,position:'fixed'}} >
<PlotGraph shouldRemove={triggered} checker1={checker2} senddatatoparent={handleDataFromChild} />
  </div>
        </Card.Body>
        <Card.Footer className="text-center border-top border-dark">{text}</Card.Footer>
      </Card>
      <div className="d-flex justify-content-between align-items-center w-100 p-3 position-absolute bottom-0">
        <div className="d-flex gap-2">
          <Button variant="primary" onClick={handlecarstart}>Start Car</Button>
          <Button 
            variant="warning" 
            onClick={() => { 
              handleToggleLineRemoval();             
            }}
          >
Clear
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default TestComp;
