
import React, { useEffect, useRef, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import chemicons from './jsons/chemicons.json';
import { Player } from '@lottiefiles/react-lottie-player';
import physicsicon from './jsons/phyicons2.json';
import mtcicons from './jsons/mtcicons.json';
import cprac from './jsons/cpracicons.json';
import portrait from './jsons/portraitbg.json';
import mathintro from './jsons/mathintro.json';
import background1 from './jsons/images/pendu2.jpg';
import background2 from './jsons/images/phys.jpg';
import background3 from './jsons/images/phy6.jpg';
import mtch1 from './jsons/images/mtc H1.jpg';
import chem from './jsons/images/chemi.jpg';
import mtc1 from './jsons/images/mtc_1.jpg';
import phy8 from './jsons/images/phys.jpg';
import pendu from './jsons/images/pendu.jpg';
import phy6 from './jsons/images/phy6.jpg';

import { gsap } from 'gsap';
import PhysicsHeader from './PhysicsHeader';
import LoadingPage from './LoadingPages/LoadingPage';

const SubjectSelect = () => {
  const animationRef = useRef(null);
  const introref = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const bgRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3600);

    return () => clearTimeout(timer);
  }, []);


  const renderBackgroundImage = (image, index) => (
    <div
      ref={(el) => (bgRefs.current[index] = el)}
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        top: 0,
        left: 0,
        zIndex:-1
      }}
    />
  );

  useEffect(() => {
    const bgImages = bgRefs.current;
    gsap.set(bgImages, { opacity: 0 });
    gsap.to(bgImages[0], { opacity: 1, duration: 1 });
  
    const slideshowTimeline = gsap.timeline({ repeat: -1 });
  
    bgImages.forEach((bg, index) => {
      const nextIndex = (index + 1) % bgImages.length;
  
      slideshowTimeline
        .to(bg, { opacity: 1, duration: 1 }) // Ensure it fully appears
        .to(bg, { opacity: 1, duration: 4 }) // Keep it visible for 4 more seconds
        .to(bg, { opacity: 0, duration: 1 }) // Fade out in 1 second
        .to(bgImages[nextIndex], { opacity: 1, duration: 1 }, '-=1'); // Start next image fade-in
    });
  
  }, []);
  

  return (
    <>
        {isLoading? (
          <div >
          <LoadingPage/>

          </div>
        ):(
          <div>
          {/* Header */}
    
    <div
      style={{
        height: "80px",
        backgroundColor: "#f8f9fa",
        borderBottom: '2px solid #ccc',
        display: 'flex',
        justifyContent: 'space-between', // Adjusted for spacing
        alignItems: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        padding: '0 20px', // Optional padding for better spacing
      }}
    >
      <PhysicsHeader />
      
    </div>
    
    
          {/* Cards Section */}
          <Container className="mt-5">
            <Row className="d-flex justify-content-center">
                  {/* PhysicsSteps Card */}
                  <Col md={3} className="mb-4">
                <Card className="h-100 text-center">
                  <Card.Body>
                  <Link to="/subtopics" style={{ color:'black', width: '100%',textDecoration: 'none' }}>
    
                    <Card.Title>Physics </Card.Title>
                    <Player
            ref={animationRef}
            src={physicsicon}
            loop={true}
            autoplay={true}
            style={{ width: '300px', height: '150px' }}
          />
                    <Card.Text>
                      Explore the world of physics with step-by-step lessons.
                    </Card.Text>
                    </Link>
    
                  </Card.Body>
                </Card>
              </Col>
              {/* MathSwipe Card */}
              <Col md={3} className="mb-4">
                <Card className="h-100 text-center">
                  <Card.Body>
                  <a href="https://mathswipe.com" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'black'}}>
    
                    <Card.Title>MathSwipe</Card.Title>
                    <Player
            ref={animationRef}
            src={mtcicons}
            loop={true}
            autoplay={true}
            style={{ width: '300px', height: '150px' }}
          />
                    <Card.Text>
                     Math is the language of the world and how things work
                    </Card.Text>
                    </a>
    
                  </Card.Body>
                </Card>
              </Col>
    
          
    
              {/* Chemix Card */}
              <Col md={3} className="mb-4">
                <Card className="h-100 text-center">
                  <Card.Body>
                  <Link to="/chemistrytopics" style={{color:'black', textDecoration: 'none', width: '100%' }}>
    
                    <Card.Title>Chemistry </Card.Title>
                    <Player
            ref={animationRef}
            src={chemicons}
            loop={true}
            autoplay={true}
            style={{ width: '300px', height: '150px' }}
          />
                    <Card.Text>
                   Learn Chemistry basics .
                    </Card.Text>
                    </Link>
    
                  </Card.Body>
                </Card>
              </Col>
    
              <Col md={3} className="mb-4">
                <Card className="h-100 text-center">
                  <Card.Body>
                  <Link to="/chemistrypractopics" style={{color:'black', textDecoration: 'none', width: '100%' }}>
    
                    <Card.Title>Chemistry Practicals</Card.Title>
                    <Player
            ref={animationRef}
            src={cprac}
            loop={true}
            autoplay={true}
            style={{ color:'black',width: '300px', height: '150px' }}
          />
                    <Card.Text>
                      Virtual Lab for Chemistry Practicals
                    </Card.Text>
                    </Link>
    
                  </Card.Body>
                </Card>
              </Col>
    
            
              {/* General Science Card */}
              
            </Row>
          </Container>
      {isMobile? (
         <div >
         {renderBackgroundImage(chem, 0)}
             {renderBackgroundImage(mtch1, 1)}
             {renderBackgroundImage(chem, 2)}
         </div>
      ):
      (
        <div>
        {renderBackgroundImage(pendu, 0)}
            {renderBackgroundImage(background2, 1)}
            {renderBackgroundImage(phy6, 3)}
            {renderBackgroundImage(mtc1, 2)}
            {renderBackgroundImage(phy8, 4)}
            
        </div>
      )}
     
        </div>
        )}

    </>
  
  );
};

export default SubjectSelect;
