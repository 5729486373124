// src/auth.js
import { auth, db } from './Firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, serverTimestamp, updateDoc, increment,getDoc,collection } from 'firebase/firestore';
import React, { useState, useEffect } from "react";

// Existing signup function
export const signup3 = async (name, email, password, dateOfBirth) => {

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    const userDoc = {
      name,
      dateOfBirth,
      email,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      mEp: 0,
      mEf: 0,
      mDp: 0,
      mDf: 0,
      mKp: 0,
      mKf: 0,
      mEp2: 0,
      mEf2: 0,
      mDp2: 0,
      mDf2: 0,
      mKp2: 0,
      mKf2: 0,
      totalScore: 0,
      totalfailed: 0,
      M1: 0,
      Mn: 0,
      Level1:0,
      comments:null,
      selection:null,
    };
    await setDoc(doc(db, 'users', user.uid), userDoc);

    console.log('User signed up successfully', userDoc);
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

export const signup = async (name, email, password, dateOfBirth) => {
  try {
    // Create user with email and password
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const userRef = doc(db, 'users', user.uid);

    // User document structure
    const userData = {
      name,
      dateOfBirth,
      email,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      totalScore: 0,
      totalfailed: 0,
      comments: null,
      selection: null,
    };

    // Save user details
    await setDoc(userRef, userData);

    // Create sub-collections: math, physics, and chemistry
    const subjects = ['math', 'physics', 'chemistry'];
    const defaultStats = {
      passed: 0,
      failed: 0,
      timespent: 0,
    };

    for (const subject of subjects) {
      const subjectRef = doc(collection(userRef, subject), 'stats');
      await setDoc(subjectRef, defaultStats);
    }

    console.log('User signed up successfully with subjects initialized', userData);
    return user;
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

// Existing login function
export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log('Login successful:', user);
    return true;
  } catch (error) {
    console.error('Error during login:', error);
    return false;
  }
};

// New updatemEp function
export const updatemEp = async (user2) => {
  try {
    const docRef = doc(db, 'users', user2.uid);
    await updateDoc(docRef, {
      mEp: increment(1)
    });
    console.log('mEp incremented successfully');
  } catch (error) {
    console.error('Error incrementing mEp:', error);
  }
};

export const updatemEf = async (user) => {
  try {
    const docRef = doc(db, 'users', user.uid);
    await updateDoc(docRef, {
      mEf: increment(1)
    });
    console.log('mEf incremented successfully'); // Corrected message
  } catch (error) {
    console.error('Error incrementing mEf:', error); // Corrected error message
  }
};


export const updatemDp = async (user) => {
  try {
    const docRef = doc(db, 'users', user.uid);
    await updateDoc(docRef, {
      mDp: increment(1)
    });
    console.log('mEp incremented successfully');
  } catch (error) {
    console.error('Error incrementing mDp:', error);
  }
};

export const updatemDf = async (user) => {
  try {
    const docRef = doc(db, 'users', user.uid);
    await updateDoc(docRef, {
      mDf: increment(1)
    });
    console.log('mEp incremented successfully');
  } catch (error) {
    console.error('Error incrementing mDp:', error);
  }
};
export const fetchmEpScore = async (user) => {
  try {
    const docRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.mEp || 0; // Return mEp if exists, otherwise 0
    } else {
      console.log('No such document!');
      return 0;
    }
  } catch (error) {
    console.error('Error fetching mEp score:', error);
    return 0;
  }
};
const incrementField = async (userUID, subject, field) => {
  if (!userUID) {
    console.error("User UID is missing");
    return;
  }

  try {
    // Reference to the stats document inside the subject sub-collection
    const statsDocRef = doc(db, "users", userUID, subject, "stats");
    const statsDoc = await getDoc(statsDocRef);

    if (!statsDoc.exists()) {
      console.error(`Stats document not found for ${subject}`);
      return;
    }

    // Get current value and increment
    const currentValue = statsDoc.data()[field] || 0;
    await updateDoc(statsDocRef, {
      [field]: currentValue + 1
    });

    console.log(`${subject} ${field} incremented:`, currentValue + 1);
  } catch (error) {
    console.error(`Error updating ${subject} performance:`, error);
  }
};

// Functions for Physics
export const incrementPhysicsPassed = (userUID) => incrementField(userUID, "physics", "passed");
export const incrementPhysicsFailed = (userUID) => incrementField(userUID, "physics", "failed");

// Functions for Math
export const incrementMathPassed = (userUID) => incrementField(userUID, "math", "passed");
export const incrementMathFailed = (userUID) => incrementField(userUID, "math", "failed");

// Functions for Chemistry
export const incrementChemistryPassed = (userUID) => incrementField(userUID, "chemistry", "passed");
export const incrementChemistryFailed = (userUID) => incrementField(userUID, "chemistry", "failed");
