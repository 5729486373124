import React, { useRef, useEffect, useState } from 'react';
import { Draggable } from "gsap/all";
import { gsap } from "gsap";
import quadini from '../jsons/2quads.mp3';
import { Player } from '@lottiefiles/react-lottie-player';
import correctsound from '../jsons/genius ke.mp3';
import wrongsound from '../jsons/wronganswer.mp3';

const SwipeMatch = ({sendDataToParent}) => {
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const box3Ref = useRef(null);
  const box4Ref = useRef(null);
  const box5Ref = useRef(null);
  const box6Ref = useRef(null);
  const audioref2 = useRef(null);

  const [sounding, setSounding] = useState(quadini);
  const [acscore, setAcscore] = useState(0);
  const [check1, setcheck1] = useState(0);
  const [check2, setcheck2] = useState(0);
  const [check3, setcheck3] = useState(0);

  const BoxStyle = {
    cursor: 'move',
    backgroundColor: 'skyblue',
    width: '80px', // width for better visualization
    height: '80px',
    margin: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const handleAudioLoad = () => {
    if (audioref2.current) {
    
        audioref2.current.play().catch((error) => {
          console.error("Audio play interrupted:", error);
        });
    
    }
  };
  const sendData = () => {
    const data = true;
    
    sendDataToParent(data,acscore);
  };


  useEffect(() => {
    const boxes = [box1Ref, box2Ref, box3Ref, box4Ref, box5Ref, box6Ref]; // Define the array of box references

    // Store the initial position of box1
    const box1InitialPosition = { x: 0, y: 0 };

    boxes.forEach((boxRef) => {
      Draggable.create(boxRef.current, {
        type: "x,y",
        onDragStart: function() {
          if (this.target === box1Ref.current) {
            box1InitialPosition.x = this.x;
            box1InitialPosition.y = this.y;
          }
        },
        onDragEnd: function() {
          const box1 = box1Ref.current;
          const box2 = box2Ref.current;
          const box4 = box4Ref.current;
          const box6 = box6Ref.current;
          const box4text = box4.textContent;

          const handleCorrectMatch = () => {
            setAcscore(1);
            setSounding(correctsound);
          };

          const handleIncorrectMatch = () => {
            setAcscore(0);
            setSounding(wrongsound);
          };

          if (this.hitTest(box4, "50%")) {
            setcheck1(1);

            if (this.target === box1Ref.current) {
              gsap.to(box1Ref.current, { backgroundColor: 'green', duration: 1, ease: "power1.inOut" });
              gsap.to(box4, { backgroundColor: 'green', duration: 1, ease: "power1.inOut" });
              box4.textContent = "x+x=2x";
              gsap.to(box1Ref.current, { opacity: '0', duration: 1, ease: "power1.inOut" });
              handleCorrectMatch();
            } else {
              gsap.to(this.target, { x: box1InitialPosition.x, y: box1InitialPosition.y, duration: 1.5 });
              gsap.to(this.target, { backgroundColor: 'red', duration: 1, ease: "power1.inOut" });
              gsap.to(box4, { backgroundColor: 'red', duration: 1, ease: "power1.inOut" });
              handleIncorrectMatch();
            }
          } else if (this.hitTest(box6, "50%")) {
            setcheck2(1);

            if (this.target === box2Ref.current) {
              gsap.to(box2Ref.current, { backgroundColor: 'green', duration: 1, ease: "power1.inOut" });
              gsap.to(box6, { backgroundColor: 'green', duration: 1, ease: "power1.inOut" });
              gsap.to(box2Ref.current, { opacity: '0', duration: 1, ease: "power1.inOut" });
              handleCorrectMatch();
            } else {
              gsap.to(this.target, { x: box1InitialPosition.x, y: box1InitialPosition.y, duration: 1.5 });
              gsap.to(this.target, { backgroundColor: 'red', duration: 1, ease: "power1.inOut" });
              gsap.to(box6, { backgroundColor: 'red', duration: 1, ease: "power1.inOut" });
              handleIncorrectMatch();
            }
          }  else {
            gsap.to(this.target, { x: box1InitialPosition.x, y: box1InitialPosition.y, duration: 1.5 });
          }
        }
      });
    });
  }, []);

useEffect(()=> {
if(check1&&check2){
  sendData();
}

});

  return (
    <div>

    <div style={{ display: 'flex',fontFamily:'helvetica',fontSize:'20px' ,fontWeight:'bold'}}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div ref={box1Ref} style={BoxStyle}>
          x
        </div>
        <div ref={box2Ref} style={BoxStyle}>
          x²
        </div>
      
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '200px' }}>
      <div ref={box6Ref} style={BoxStyle}>
          2x²
        </div>
      <div ref={box4Ref} style={BoxStyle}>
          x
        </div>
     
       
      </div>
    </div>
    <div>
            <Player>
              <audio autoplay={true} ref={audioref2} src={sounding} type="audio/mp3" onLoadedData={handleAudioLoad} />
            </Player>
          </div>
    </div>

  );
};

export default SwipeMatch;
