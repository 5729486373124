import React, { useRef,useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './jsons/images/scistepslogo.png';
import { Card, Container, Row, Col,Nav,Navbar,Carousel,Button } from 'react-bootstrap';
import Cprac1 from './Chemix/Cprac1';
import Sim2 from './Experiments/Sim6';
import Sim3 from "./Experiments/Sim3";
import Sim4 from "./Experiments/Sim4";
import Sim5 from "./Experiments/Sim5";
import Sim6 from "./Experiments/Sim6";
import Sim7 from "./Experiments/Sim7";
import { Link } from 'react-router-dom';
import teacheraudio from './audiofiles/teacher.mp3';
import studentaudio from './audiofiles/barbara.mp3';
import video from './videos/Scistepsexplain.mp4';
import { Player } from "@lottiefiles/react-lottie-player";
import { Bar } from 'react-chartjs-2';
import cog1 from './jsons/cog1.json';
import ess1 from './jsons/images/Ess.png';
import ms1 from './jsons/images/ms1.jpg';
import nonlineardefinition from './jsons/nonlineardefinition.json';
import lineardefinition from './jsons/lineardefinition.json';
import NotificationPopup from "./NotificationPopup"; // Adjust the path if needed

import missionImage from './jsons/svgs/science.svg';
import emoticon from './jsons/svgs/emoticon.svg';
import access from './jsons/svgs/access.svg';
import Slider from "react-slick";
import ohm from './jsons/images/ohmslaw.png';
import ess2 from './jsons/images/ess2.png';
import inter from './jsons/images/interview01.jpg';
import mtn1 from './jsons/images/mtn1.jpg';
import square from './jsons/images/square.jpg';
import showcase from './jsons/images/showcase.jpg';
import students from './jsons/images/students.jpg';
import smile from './jsons/images/smile.jpg';
import hold from './jsons/images/hold.jpg';
import whitesci2 from './jsons/images/bulbwhte.jpg';
import whitesci from './jsons/images/sci2.jpg';
import './about.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import book1 from './jsons/freepic/book1.json';
import brain from './jsons/freepic/brain.json';
import digitalscience from './jsons/freepic/digitalscience.json';
import expand1 from './jsons/freepic/expandteam.json';
import global from './jsons/freepic/global.json';
import mobileapp from './jsons/freepic/mobileapp.json';
import learn from './jsons/freepic/learn.json';
import { gsap } from "gsap";


import inventors from './jsons/freepic/inventors.json';
import team from './jsons/freepic/team.json';
import scienceicon from './jsons/freepic/scienceicon.json';
import journey from './jsons/freepic/journey.json';
import digitize from './jsons/freepic/digitize.json';
import expand2 from './jsons/freepic/expandteam2.json';
import rocket from './jsons/freepic/rocket.json';
import digi from './jsons/freepic/inter.json';
import scientist from './jsons/freepic/scientist.json';
import quad1 from './jsons/quad1.json';
import quad2 from './jsons/quad2.json';
import cog2balance from './jsons/cog2balance.json';
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const animationref = useRef(null);
  const audioref = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
const notref =  useRef(null);
  const images = [ess1,ohm,ess2,]; // Add more images or videos as needed
const exhibit = [inter,students,smile,mtn1,ms1,showcase,hold]; // Add more images or videos as needed
const learners = [nonlineardefinition,lineardefinition, cog1,cog2balance, quad1, quad2]; // Array of animations
const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0); // Track current index
const [dataFromChild, setDataFromChild] = useState('');
const [question, setquestion] = useState("select which one is more stable, A or B ?");
const [counter, setCounter] = useState(1); // Add counter state
const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

const [scorevalue, setscorevalue] = useState(0); // Track current index
const sections = ["Home", "About", "updates", "Contact"];
const section1Ref = useRef(null);
const section2Ref = useRef(null);
const section3Ref = useRef(null);
const mathref = useRef(null);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    gsap.set( section1Ref.current, { 
      opacity: 0, 
      y: -50 // Start 50px above their current position 
    });
    gsap.set( mathref.current, { 
      opacity: 0, 
    });
    
    gsap.set( section2Ref.current, { 
      opacity: 0, 
      y: -50 // Start 50px above their current position 
    });
    gsap.set( section3Ref.current, { 
      opacity: 0, 
      y: -50 // Start 50px above their current position 
    });
    
    gsap.to(section1Ref.current, {
      duration: 1.7,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 0.3,
    });

    gsap.to(section2Ref.current, {
      duration: 1,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power3.inOut",
      delay: 1.2,
    });
    gsap.to(section3Ref.current, {
      duration: 1.5,
      opacity: 1,
      y: 0, // Move to original position
      ease: "power4.inOut",
      delay: 2,
    });
    gsap.to(mathref.current, {
      duration: 1.5,
      opacity: 1,
      ease: "power4.inOut",
      delay: 15.2,
    });
  }, []);
  

// State to track the current component index
const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  const [learningcomponents, setlearningcomponents] = useState(nonlineardefinition);
  const nextAnimation = () => {
    setCurrentAnimationIndex((prevIndex) => (prevIndex + 1) % learners.length);

  };
  const elementsRef = useRef([]);

 
  const parentStyle = {
    background: 'linear-gradient(to right, #2C2575, #91306D)', // Blue gradient
    width: '100vw', // Full width of the viewport
    position: 'relative',

  };
  const handleAudioLoad = () => {
    if (audioref.current) {
      if(counter>1){
        audioref.current.play().catch((error) => {
          console.error("Audio play interrupted:", error);
        });
      }
     
    }
  };
  const handleNextClick = () => {
    setCurrentComponentIndex((prevIndex) => (prevIndex + 1) % components.length);
  
    setCounter((prevCounter) => {
      const newCounter = Math.max(prevCounter + 1, 0); // Ensure the counter doesn't go below 0
  
      if (newCounter === 1) {
        setquestion("Select which one is more stable, A or B ?");
      } 
      else if (newCounter === 2) {
        setquestion(" Select which one is more stable, A or B ?");
      } 
      else if (newCounter === 3) {
        setquestion("Select the center of gravity");
      } 
      else if (newCounter === 4) {
        setquestion("Select the center of gravity");
      } 
      
      else  {
        setquestion("which one is more stable, A or B ?");
       
      } 
     
     
  
      return newCounter;
    });
  };
  const data = {
    labels: ['Math', 'Physics', 'Chemistry'], // Subjects
    datasets: [
      {
        label: 'Performance (%)',
        data: [75, 85, 90], // Sample scores for the subjects
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Color of the bars
        borderColor: 'rgba(54, 162, 235, 1)', // Border color of bars
        borderWidth: 1,
      },
    ],
  };
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
    if(data){
        setscorevalue(scorevalue+1);

    }
   
  };
  useEffect(() => {
    const fadeElements = document.querySelectorAll('.fade-opacity');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, { threshold: 0.1 }); // Trigger when at least 10% of the element is visible

    fadeElements.forEach(element => {
      observer.observe(element);
    });

    return () => {
      fadeElements.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);
  const components = [<Sim2 sendDataToParent={handleDataFromChild} />, <Sim3 sendDataToParent={handleDataFromChild} />, <Sim4 sendDataToParent={handleDataFromChild}/>, <Sim5 sendDataToParent={handleDataFromChild}/> , ];

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Your Performance',
      },
    },
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  

  
  const colorPalette = {
    primary: '#84250C',
    secondary: '#3C393E',
    light: '#F5F4EB'
  };

  return (
    
    <div  className="about-page">
       <div ref={mathref} style={{position:'fixed',zIndex:5}}>
      <NotificationPopup />
    </div>
  <div  className="about-page">

       <Navbar
        variant="dark"
        fixed="top"
        expand="lg"
        className="header"
              >
        <Container>
          <Navbar.Brand href="/subjectselect" style={{ color: colorPalette.light }}>
          <div style={{display:'flex',top:'10px',position:'fixed'}}>
          <img src={whitesci} style={{width:'33px',height:'30px'}}/>
<p style={{marginbottom:'10px',padding:'5px'}}>
Start Scisteps</p>

             </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
  <Nav className="ms-auto navbar-nav">
    <Nav.Link href="#home" className="text-white">
      About
    </Nav.Link>
    
    <Nav.Link href="#updates" className="text-white">
      Updates
    </Nav.Link>
   
    <Nav.Link href="#contact" className="text-white">
      Contact Us
    </Nav.Link>
  </Nav>
</Navbar.Collapse>


        </Container>
      </Navbar>
      {/* Hero Section */}
      <div ref={section1Ref} id="home" className="hero text-white text-center py-5" style={{ backgroundColor: colorPalette.primary,padding:'30px'}}>
        <h1 style={{padding:'30px'}}>Welcome to Scisteps</h1>
        <p className="lead">
          Revolutionizing science education with interactive simulations and experiments.
        </p>
        <Link to='/previewpage' style={{ textDecoration: 'none', marginTop: '10px' }}>

        <button
          style={{
            backgroundColor:colorPalette.light,
            color: colorPalette.secondary,
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight:'bold'
          }}
        >
PREVIEW        </button>
        </Link>
      </div>
     
      {/* About Section */}
      <div ref={section2Ref} className="container my-5" style={{ backgroundColor: colorPalette.light,borderRadius:'50px' }}>
        <div className="row align-items-center" style={{padding:'20px', borderRadius:'25px'}}>
        <div className="col-md-4">
  <video controls className="img-fluid rounded">
    <source src={video} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
<br/>

<div ref={section3Ref} className="col-md-6">
  <h2>What is Sci-Steps?</h2>
  <p>
    Sci-Steps is a project designed to transform science learning through the power of <strong>animations</strong>,
     <strong> interactive games</strong>, <strong>hands-on experiments</strong>,<strong>while gathering user analytics</strong>.
       Sci-Steps makes science more accessible, interactive, and fun for students of all levels.
  </p>
  <p>
    Established in 2024 by <strong><Link to="/founder">Sam Nkurunungi</Link></strong>,
     Sci-Steps is committed to advancing science education through continuous innovation. 
     
  </p>
<p>
The platform also features <strong>MathSwipe</strong>  MathSwipe makes math feel less like a chore and more like a game.
      With interactive tools and brain-teasing puzzles,
       it’s math like you've never experienced before! 
  </p>

  <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
    <Link to='/subjectselect' style={{ textDecoration: 'none' }}>
      <button
        style={{
          backgroundColor: colorPalette.primary,
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '1rem',
        }}
      >
        Start Sci-Steps
      </button>
    </Link>
    <Link to='/founder' style={{ textDecoration: 'none' }}>
      <button
        style={{
          backgroundColor: colorPalette.secondary,
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '1rem',
        }}
      >
        Meet the Founder
      </button>
    </Link>
  </div>
</div>

        </div>
      </div>

  {/* Mission Section */}
  <Container
  ref={section3Ref}
  fluid
  className="my-5 text-center"
  style={{ backgroundColor: 'white' }}
>
  <h2 id="about" className="text-center mb-4" style={{color: colorPalette.primary ,padding:'20px'}}>
    Our Mission
  </h2>
  <Row className="justify-content-center">
    <Col md={6} className="d-flex flex-column align-items-center">
      {/* First Image and Text */}
      <div
              style={{ width:'150px', height: '150px', }}
            >
              <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={rocket}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
      <p style={{ fontSize: "1.1rem", color: colorPalette.primary}}>
        To make math and science <strong>more fun</strong> while fostering a{" "}
        <strong>deep love</strong> for learning and exploration in science
        subjects.
      </p>

      {/* Second Image and Text */}
      <div
              style={{ width:'150px', height: '150px', }}
            >
              <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={scientist}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
      <p style={{ fontSize: "1.1rem",padding:'30px',color: colorPalette.primary }}>
        To make math and science education <strong>interactive and accessible</strong>.
      </p>
    </Col>
  </Row>
</Container>

<br/>

  {/* Goals Section */}
  <Container fluid className="my-5" style={{ backgroundColor: colorPalette.light,borderRadius:'30px',padding:'60px' }}>
        <h2 className="text-center mb-4 " style={{ color: 'black',}} >Our Goals</h2>
        <Row className="g-4 justify-content-center">
          {/* Goal 1 */}
          <Col md={5}>
            <Card className="h-100 shadow-sm border-0" style={{borderRadius:'30px',padding:'40px'}}>
              <Row className="align-items-center">
                <Col md={6} style={{borderRadius:'30px'}}>
                <div
              style={{ width:'150px', height: '150px', }}
            >
              <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={inventors}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
                </Col>
                <Col md={6} style={{borderRadius:'30px'}}>
                  <h5>Inspire the Next Generation of Inventors</h5>
                  <p>
                    Empower students to think critically, innovate, and build solutions to real-world problems by cultivating curiosity in science and math.
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* Goal 2 */}
          <Col md={5}>
          <Card className="h-100 shadow-sm border-0" style={{borderRadius:'30px',padding:'40px'}}>
          <Row className="align-items-center">
                <Col md={5}>
                <div
              style={{ width:'150px', height: '150px', }}
            >
              <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={digi}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
                </Col>
                <Col md={6} className="p-3" style={{padding:'30px'}}>
                  <h5>Digitize Science Education</h5>
                  <p>
                    Transform traditional science subjects into **engaging, digital learning experiences**, making them more accessible and interactive.
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <br/>

    

      {/* Services Section */}
      <div id="updates" className="py-5" style={{ backgroundColor: colorPalette.secondary, borderRadius: "50px", padding: "20px" }}>
      <Container>
        <h2 className="text-center mb-4" style={{ color: colorPalette.light, padding: "30px" }}>
          Updates and Features
        </h2>
        <Carousel activeIndex={index} onSelect={handleSelect} interval={5000} indicators={false}>
          {/* Learning Modules */}
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card style={{ borderRadius: "30px", overflow: "hidden", maxHeight: "500px" }}>
                  <Card.Header style={{ backgroundColor: colorPalette.primary, color: "white", textAlign: "center" }}>
                    <h5 style={{ margin: 0 }}>Learning Components</h5>
                  </Card.Header>
                  <Card.Body className="d-flex">
                    <div className="pe-3 border-end" style={{ width: "320px", height: "300px" }}>
                      <Player ref={animationref} autoplay={true} loop={true} src={learners[currentAnimationIndex]} style={{ width: "100%", height: "100%" }} />
                    </div>
                    <div className="ms-3 d-flex align-items-center" style={{ flex: "1", fontSize: "0.9rem", maxWidth: "30%" }}>
                      Explore topics with detailed explanations and interactive content.
                    </div>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <button className="btn btn-primary" onClick={nextAnimation}>
                      Next
                    </button>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>

          {/* Experiment Modules */}
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card style={{ borderRadius: "50px", overflow: "hidden", maxHeight: "500px" }}>
                  <Card.Header style={{ backgroundColor: colorPalette.primary, color: "white", textAlign: "center" }}>
                    <h5 style={{ margin: 0 }}>Virtual Experiments</h5>
                  </Card.Header>
                  <Card.Body className="d-flex">
                    <div className="pe-3 border-end" style={{ width: "400px", height: "350px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Cprac1 style={{ width: "380px", height: "140px" }} />
                    </div>
                    <div className="ms-3 d-flex align-items-center" style={{ flex: "1", fontSize: "0.9rem", maxWidth: "30%" }}>
                      Observe and test how different metals react when exposed to water.
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>

          {/* Tests */}
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card style={{ borderRadius: "30px", overflow: "hidden", maxHeight: "500px" }}>
                  <Card.Header style={{ backgroundColor: colorPalette.primary, color: "white", textAlign: "center" }}>
                    <h5 style={{ margin: 0 }}>Tests</h5>
                  </Card.Header>
                  <Card.Body className="d-flex">
                    <div className="pe-3 border-end" style={{ width: "400px", height: "250px" }}>
                      {components[currentComponentIndex]}
                    </div>
                    <div className="ms-3 d-flex align-items-center" style={{ flex: "1", flexDirection: "column", fontSize: "0.9rem", maxWidth: "35%" }}>
                      <p style={{ fontWeight: "bolder" }}>Challenge yourself and improve through practice tests.</p>
                      <p>{question}</p>
                    </div>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <p>Attempt by selecting one</p>
                    <Button className="btn btn-primary" onClick={handleNextClick}>
                      Next
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>

          {/* Track Progress */}
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={8}>
                <Card style={{ borderRadius: "30px", overflow: "hidden", maxHeight: "500px" }}>
                  <Card.Header style={{ backgroundColor: colorPalette.primary, color: "white", textAlign: "center" }}>
                    <h5 style={{ margin: 0 }}>Track Progress</h5>
                  </Card.Header>
                  <Card.Body className="d-flex">
                    <div className="pe-3 border-end" style={{ width: "60%", height: "250px" }}>
                      <Bar data={data} options={options} />
                    </div>
                    <div className="ms-3 d-flex align-items-center" style={{ display: "flex", fontSize: "0.9rem", maxWidth: "35%", flexDirection: isMobile ? "column" : "row" }}>
                      Monitor your performance with detailed analytics and feedback.
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
    <br/>

      {/* Interview Section */}
<div
  id="interview"
  className="py-5"
  style={{
    backgroundColor: colorPalette.primary,
    borderRadius: '50px',
    padding: '20px',
  }}
>
  <Container>
    <h2
      className="text-center mb-4"
      style={{ color: colorPalette.light, padding: '30px' }}
    >
Research and Notes    </h2>
    <Row >
      {/* Learning Modules */}
      <Col md={6} xs={12} style={{ padding: '15px' }}>
        <Card
          style={{ borderRadius: '30px', overflow: 'hidden', maxHeight: '600px', }}
        >
          <Card.Header
            style={{
              backgroundColor: colorPalette.secondary,
              color: 'white',
              textAlign: 'center',
            }}
          >
            <h5 style={{ margin: 0, fontSize: '16px' }}>
              Interview with Math and Physics Teacher, Alia Ambrose 
            </h5>
          </Card.Header>
          <Card.Body style={{ fontSize: '14px', maxHeight: '500px', overflowY: 'auto' }}>
            <div>
              <div style={{ marginBottom: '15px' }}>
                <audio ref={audioref} src={teacheraudio} type="audio/mp3" controls />
              </div>
              <p><strong>Challenges Faced:</strong></p>
              <ul>
                <li>
                  <strong>Holiday Learning Gap:</strong> Students often forget
                  material over holidays, requiring teachers to spend valuable
                  time reteaching foundational concepts.
                </li>
                <li>
                  <strong>Limited Practical Sessions:</strong> Due to resource
                  constraints, schools struggle to provide sufficient
                  hands-on experiments.
                </li>
                <li>
                  <strong>Difficulty with Electro-Magnetism:</strong> Students
                  find the topic abstract and challenging to visualize.
                </li>
              </ul>
              <p><strong>Proposed Solutions with Scisteps:</strong></p>
              <ul>
                <li>
                  <strong>Holiday Gap:</strong> Scisteps offers interactive
                  science games to keep students engaged and learning during
                  holidays.
                </li>
                <li>
                  <strong>Practicals:</strong> Virtual experiments allow students
                  to practice and understand concepts both at school and at home.
                </li>
                <li>
                  <strong>Electro-Magnetism:</strong> Interactive simulations and
                  tools simplify the topic, improving students’ understanding and
                  engagement.
                </li>
              </ul>
            </div>
          </Card.Body>
          <Card.Footer className="text-center">Teacher from St.Mary's Kitende</Card.Footer>
        </Card>
      </Col>

      {/* Interview Section - Challenges Faced by Barbara */}
      <Col md={6} style={{ padding: '15px' }}>
        <Card
          style={{ borderRadius: '30px', overflow: 'hidden', maxHeight: '600px' }}
        >
          <Card.Header
            style={{
              backgroundColor: colorPalette.primary,
              color: 'white',
              textAlign: 'center',
            }}
          >
            <h5 style={{ margin: 0, fontSize: '16px' }}>
              Interview with Chemistry Student, Akatukunda Barbara
            </h5>
          </Card.Header>
          <Card.Body style={{ fontSize: '14px', maxHeight: '500px', overflowY: 'auto' }}>
            <div>
              <div style={{ marginBottom: '15px' }}>
                <audio ref={audioref} src={studentaudio} type="audio/mp3" controls />
              </div>
              <p><strong>Challenges Faced:</strong></p>
              <ul>
                <li>
                  <strong>Holiday Learning Tools:</strong> Barbara loves science animations and she hopes there would be more
                   animations and interactive content to explore more.
                   </li> 
                <li>
                  <strong>Inorganic Chemistry Struggles:</strong> She finds
                  the subject challenging due to the complexity of equations and
                  calculations, which often forces her to cram.
                </li>
              </ul>
              <p><strong>Proposed Solutions with Scisteps:</strong></p>
              <ul>
                <li>
                  <strong>Interactive Content:</strong> Scisteps provides fun
                  animations and visual aids to make holiday learning more engaging
                  and effective.
                </li>
                <li>
                  <strong>Equation Simplifiers:</strong> Gamified tools simplify
                  equations, making them easy to understand and remember.
                </li>
              </ul>
            </div>
          </Card.Body>
          <Card.Footer className="text-center">Student from St.Mary's Kitende secondary school</Card.Footer>
        </Card>
      </Col>
    </Row>
  </Container>
</div>
<br/>



      {/* Future Plans */}
      <div
  className="container my-5"
  style={{
    backgroundColor: colorPalette.light,
    padding: "50px",
    borderRadius: "50px",
  }}
>
  <h2 className="text-center mb-5">Future Plans</h2>
  <div className="row g-4">
    {/* Expand Subject Areas */}
    <div style={{padding:'10px'}} className="col-md-6">
      <Card
        className="h-100"
        style={{
          borderRadius: "50px",
          overflow: "hidden",
          padding: "25px",
          marginBottom: "30px",
        }}
      >
        <Card.Header
          className="text-center"
          style={{
            backgroundColor: "white",
            padding: "15px",
            marginBottom: "15px",
          }}
        >
          <h5>1. Create More Lessons, Tests, and Games</h5>
        </Card.Header>
        <Card.Body style={{ paddingBottom: "25px" }}>
          <div style={{display:'flex', flexDirection: isMobile ? 'column' : 'row',}}>
          <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={learn}
                style={{ width: '200px', height: '80px',marginRight:'20px' }}
              />
              <div style={{flexDirection:'column',display:'flex'}}>
              <p style={{ marginBottom: "25px",padding:'10px' }}>
            Develop interactive lessons, quizzes, and games for a wide range of
            topics and subtopics in the following subjects:
          </p>
          <ul style={{ paddingBottom: "15px" }}>
            <li>Physics</li>
            <li>Mathematics</li>
            <li>Chemistry</li>
          </ul>
              </div>
  

        
          
          </div>

        </Card.Body>
      </Card>
    </div>

    {/* Team Expansion */}
    <div style={{padding:'20px'}} className="col-md-6">
      <Card
        className="h-100"
        style={{
          borderRadius: "50px",
          overflow: "hidden",
          padding: "25px",
          marginBottom: "30px",
        }}
      >
        <Card.Header
          className="text-center"
          style={{
            backgroundColor: "white",
            padding: "15px",
            marginBottom: "15px",
          }}
        >
          <div >
        
          <h5 style={{padding:'10px'}}>2. Expand the Team</h5>

        
          
          </div>
        </Card.Header>
        <Card.Body style={{ paddingBottom: "25px" }}>
          <div style={{display:'flex',    flexDirection: isMobile ? 'column' : 'row', // Conditional flexDirection
}}>
          <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={expand2}
                style={{ width: '200px', height: '80px',marginRight:'20px' }}
              />
               <p style={{marginRight:'20px',padding:'10px'}}>
            To accelerate content creation, we plan to hire additional
            animators, software developers, teachers, and scriptwriters to
            ensure a comprehensive and engaging experience for users.
          </p>
          </div>
         
        </Card.Body>
      </Card>
    </div>

    {/* Mobile App Development */}
    <div style={{padding:'20px'}} className="col-md-6">
      <Card
        className="h-100"
        style={{
          borderRadius: "50px",
          overflow: "hidden",
          padding: "25px",
          marginBottom: "30px",
        }}
      >
        <Card.Header
          className="text-center"
          style={{
            backgroundColor: "white",
            padding: "15px",
            marginBottom: "15px",
          }}
        >
          <h5>3. Mobile App Development</h5>
        </Card.Header>
        <Card.Body style={{ paddingBottom: "25px" }}>
          <div style={{display:'flex', flexDirection: isMobile ? 'column' : 'row',}}>
          <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={mobileapp}
                style={{ width: '200px', height: '80px',marginRight:'20px' }}
              />
<p style={{ marginBottom: "25px" ,padding:'10px'}}>
            Launch a mobile application for students to access Scistep content
            anytime and anywhere, making learning more accessible.
          </p>
          </div>
          
        </Card.Body>
      </Card>
    </div>

    {/* Global Outreach */}
    <div style={{padding:'20px'}} className="col-md-6">
      <Card
        className="h-100"
        style={{
          borderRadius: "50px",
          overflow: "hidden",
          padding: "25px",
          marginBottom: "30px",
        }}
      >
        <Card.Header
          className="text-center"
          style={{
            backgroundColor: "white",
            padding: "15px",
            marginBottom: "15px",
          }}
        >
          <h5>4. Global Outreach</h5>
        </Card.Header>
        <Card.Body style={{ paddingBottom: "25px" }}>
          <div style={{display:'flex', flexDirection: isMobile ? 'column' : 'row',}}>
          <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={global}
                style={{ width: '200px', height: '80px',marginRight:'20px' }}
              />
               <p style={{ marginBottom: "25px" ,padding:'10px'}}>
            Collaborate with schools and educational institutions worldwide to
            integrate Scistep into their curriculum and reach a broader
            audience.
          </p>
          </div>
         
        </Card.Body>
      </Card>
    </div>

    {/* Call for Support */}
    <div className="col-md-12">
      <Card
        className="h-100"
        style={{
          borderRadius: "50px",
          overflow: "hidden",
          padding: "25px",
          marginBottom: "30px",
        }}
      >
        <Card.Header
          className="text-center"
          style={{
            backgroundColor: "white",
            padding: "15px",
            marginBottom: "15px",
          }}
        >
          <h5>5. Call for Support</h5>
        </Card.Header>
        <Card.Body style={{ paddingBottom: "25px" }}>
          <p style={{ marginBottom: "25px" }}>
            To bring these plans to life, we invite voluntary team members to
            join our mission, and we welcome contributions through donations and
            funding to scale our efforts and make Scistep accessible to millions
            of learners globally.
          </p>
        </Card.Body>
      </Card>
    </div>
  </div>
</div>



      {/* Journey/History Section */}
      <div
className="py-5"  style={{
    ...parentStyle,
    borderRadius: '50px',
    padding: '5px',
  }}
>
  <div style={{display:'flex', flexDirection: isMobile ? 'column' : 'row',alignItems:'center',justifyContent:'center',justifyItems:'center',align:'center'}}>
  <Player
                ref={animationref}
                autoplay={true}
                loop={true}
                src={journey}
                style={{ width: '100px', height: '150px' }}
              />
  <h2 className="text-center mb-5 text-white">Journey & History</h2>

  </div>

  {/* ESS - Electricity Study Simulator */}
  <div
    className="row align-items-center mb-5 mx-auto"
    style={{
      borderRadius: '150px',
      padding: '40px',
      maxWidth: '95%',
      color:'white'
    }}
  >
    <div className="col-md-6">
      <h5>Electricity Study Simulator (ESS)</h5>
      <p>
        In 2022, Sam Nkurunungi created <strong>ESS (Electricity Study Simulator)</strong>,with 2 other students, Maria Asiimwe and Mutumba Sulaiman as a final year project
        in software engineering class 2022. The project aimed to simplify the study of electricity concepts
        through interactive simulations, marking the foundation of what would become Scisteps.
      </p>
    </div>
    <div
      style={{
        width: '500px',
        height: '300px',
        margin: '0 auto',
        overflow: 'hidden',
        borderRadius: '30px', // Border radius for the slider container
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Box shadow for the slider container
      }}
    >
      <Slider
        autoplay={true}
        autoplaySpeed={3000}
        infinite={true}
        slidesToShow={1}
        slidesToScroll={1}
        dots={true}
        className="slick-carousel-container"
      >
        {images.map((image, index) => (
          <div key={index} style={{ width: '300px', height: '400px' }}>
            <img
              src={image}
              alt={`Slideshow ${index}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '30px', // Border radius for images
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  </div>

  {/* Mathswipe */}
  <div
  id="mathswipe"
    className="row align-items-center mb-5 mx-auto"
    style={{
      borderRadius: '50px',
      padding: '20px',
      maxWidth: '95%',
    }}
  >
    <div style={{color:'white',display:'flex',flexDirection:'column'}} className="col-md-6 order-md-2">
      <div>
      <h5>Mathswipe</h5>
      <p>
        In July 2024, Sam introduced <strong>Mathswipe</strong> an interactive math learning
        platform. It featured engaging lessons and practice tests for students. The project was
        showcased at the <strong>UgDevSummit 2024</strong>, gaining recognition for its innovative
        approach to education.
      </p>
      </div>
      <br/>
      <div>
      <a href="https://mathswipe.com" target="_blank" rel="noopener noreferrer">
      <button
          style={{
            backgroundColor:'#2796FF',
            color: 'black',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
        >
        Click Here to try mathswipe
        </button>
      </a>
    </div>
    <br/>

    </div>
    <div
      style={{
        width: '500px',
        height: '400px',
        margin: '0 auto',
        overflow: 'hidden',
        borderRadius: '30px', // Border radius for the slider container
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Box shadow for the slider container
      }}
    >
      <Slider
        autoplay={true}
        autoplaySpeed={3000}
        infinite={true}
        slidesToShow={1}
        slidesToScroll={1}
        dots={true}
        className="slick-carousel-container"
      >
        {exhibit.map((image, index) => (
          <div key={index} style={{ width: '300px', height: '300px' }}>
            <img
              src={image}
              alt={`Slideshow ${index}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '30px', // Border radius for images
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
   
 
  </div>

  {/* Scisteps */}
  <div
    className="row align-items-center mb-4 mx-auto"
    style={{
      backgroundColor: 'white',
      borderRadius: '50px',
      padding: '20px',
      maxWidth: '95%',
    }}
  >
    <div className="col-md-6">
      <h5>Scisteps</h5>
      <p>
        Building on the success of ESS and Mathswipe, <strong>Scisteps</strong> was born in September 2024 as a
        comprehensive science education platform, developed by the scisteps team led by me Nkurunungi Sam. scisteps integrates the best features of its
        predecessors, providing interactive simulations, experiments, and engaging content across
        various science subjects.
      </p>
    </div>
    <div className="col-md-6">
      <img
        src={logo}
        alt="Scisteps"
        className="img-fluid rounded"
        style={{ maxHeight: '400px', objectFit: 'cover' }}
      />
    </div>
  </div>

  {/* Contact Details */}
  <div
  id="contact"
    className="text-center mt-5"
    style={{
      backgroundColor: 'white',
      borderRadius: '20px',
      padding: '40px',
      maxWidth: '95%',
      margin: '0 auto',
      height:'300px'
    }}
    
  >
    <h5 className="text-center text-dark">Contact Us</h5>
    <p className="text-center text-dark">
      For any inquiries or support, feel free to reach out to us:
    </p>
    <p className="text-center text-dark">
      <strong>Email:</strong> <a href="mailto:scistepsug@gmail.com">scistepsug@gmail.com</a>
    </p>
    <p className="text-center text-dark">
      <strong>Phone:</strong> +256 780774220
    </p>
  </div>
  <div
      style={{
        position: "absolute",
        bottom: isMobile ? "50px" : "100px",
        left: isMobile ? "10%" : "10%",
        textAlign: "center",
        alignItems: "center",
        alignSelf: "center",
        width: "80%",
      }}
    >
      <p className="text-center text-dark">
        <strong>&copy; 2025 Scistepsug. All rights reserved.</strong>
      </p>
    </div>
</div>


</div>

    </div>
  );
};

export default About;
