import React, { useRef, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import ecl from "../jsons/experiment assets/electricity1.json";
import electricsound from "../jsons/sounds/electricity.mp3";
import { Card, Container, Row, Col, Button } from "react-bootstrap";

const Electricity1 = () => {
  const playerRef = useRef(null);
  const audioRef = useRef(new Audio(electricsound));
  const [isPlaying, setIsPlaying] = useState(false);
  const [started, setstarted] = useState(false);

  const handleStart = () => {
    setstarted(false);
    setTimeout(() => {
      setIsPlaying(true);

    }, 500);
    playerRef.current.play(); // Start animation
    audioRef.current.play().catch((error) => console.log("Audio blocked:", error)); // Start audio
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="justify-content-center flex-wrap">
          <Col xs={12} md={8} lg={7}>
            <Card
              style={{
                textAlign: "center",
                width: "70vw",
                maxWidth: "800px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Card.Header style={{ backgroundColor: "skyblue", width: "100%" }}>
                <h3>Electricity</h3>
              </Card.Header>

              <Card.Body
                style={{
                  border: "1px solid #9B9B9B",
                  boxShadow: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div style={{ width: "50%", height: "50%" }}>
                  <Player ref={playerRef} loop src={ecl} style={{ width: "100%", height: "100%" }} />
                </div>
{!started &&(
  <div>
<Button
 onClick={handleStart}
 variant="primary"
 style={{
   marginTop: "20px",
   padding: "10px 20px",
   fontSize: "18px",
 }}
 disabled={isPlaying} // Disable button after clicking
>
 Start
</Button>
  </div>
 
)}
               
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Electricity1;
