import { useState, useEffect } from "react";
import { doc, updateDoc, increment, getDoc } from "firebase/firestore";
import { db } from "./Firebase"; // Adjust path based on your project structure

const useTimer = (userUID, subject) => {
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    if (!userUID || !subject) return;

    setStartTime(Date.now()); // Store start time when component mounts

    return async () => {
      if (!startTime) return;
      
      const endTime = Date.now();
      const timeSpent = Math.floor((endTime - startTime) / 1000); // Convert ms to seconds

      try {
        const statsRef = doc(db, "users", userUID, subject, "stats");

        // Ensure document exists before updating
        const statsSnap = await getDoc(statsRef);
        if (statsSnap.exists()) {
          await updateDoc(statsRef, {
            timespent: increment(timeSpent),
          });
          console.log(`Updated ${subject} time spent: ${timeSpent} seconds`);
        } else {
          console.error(`Stats document not found for ${subject}`);
        }
      } catch (error) {
        console.error(`Error updating time spent in ${subject}:`, error);
      }
    };
  }, [userUID, subject]);

  return null; // Hook doesn't return anything visible
};

export default useTimer;
