import React, { useState, useEffect } from "react";
import { Navbar, Button, Modal } from 'react-bootstrap';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore';
import Signup from './Signupp';
import Login from './Login2';
import leadericon from './svgs/competitive.svg';

import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, ChartDataLabels);

const auth = getAuth();
const db = getFirestore();

const PhysicsHeader = () => {
  const [username, setUsername] = useState("Username");
  const [marks, setMarks] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [ppassed, setppassed] = useState(0);
  const [ptime, setptime] = useState(0);
  const [ctime, setctime] = useState(0);
  const [mtime, setmtime] = useState(0);

  const [pfailed, setpfailed] = useState(0);
  const [mpassed, setmpassed] = useState(0);
  const [mfailed, setmfailed] = useState(0);
  const [cpassed, setcpassed] = useState(0);
  const [cfailed, setcfailed] = useState(0);
  const [userUID, setUserUID] = useState(null);
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [authenticate, setAuthenticate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Handle authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticate(false);
        setUserUID(user.uid);
        fetchUserData(user);
      } else {
        setAuthenticate(true);
      }
    });

    // Clean up subscription
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
};

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Get the currently logged-in user
        const user = auth.currentUser;
        if (!user) {
          console.error("No user is logged in.");
          return;
        }
  
        // Fetch stats for each subject
        const subjects = ["math", "physics", "chemistry"];
        for (const subject of subjects) {
          const subjectRef = doc(db, "users", user.uid, subject, "stats");
          const subjectSnap = await getDoc(subjectRef);
  
          if (subjectSnap.exists()) {
            const { passed, failed,timespent } = subjectSnap.data();
  
            // Update state based on subject
            if (subject === "math") {
              setmpassed(passed);
              setmfailed(failed);
              setmtime(timespent);
            } else if (subject === "physics") {
              setppassed(passed);
              setpfailed(failed);
              setptime(timespent);
            } else if (subject === "chemistry") {
              setcpassed(passed);
              setcfailed(failed);
              setctime(timespent);
            }
          } else {
            console.warn(`No stats found for ${subject}`);
          }
        }
      } catch (error) {
        console.error("Error fetching subject stats:", error);
      }
    };
  
    fetchStats();
  }, []); // Run once when the component mounts

  const fetchUserData = async (user) => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userDocRef);

      if (userSnap.exists()) {
        setUsername(userSnap.data().name);
        setUserData(userSnap.data());
      } else {
        console.warn("No user data found.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getChartData = (subject) => {
    if (!userData) {
      console.warn("userData is empty, returning empty chart data.");
      return { labels: [], datasets: [] };
    }
  
    let passed, failed,timespent;
  
    // Assign correct values based on subject
    switch (subject.toLowerCase()) {
      case "math":
        passed = mpassed;
        failed = mfailed;
        timespent = mtime;
        break;
      case "physics":
        passed = ppassed;
        failed = pfailed;
        timespent = ptime;

        break;
      case "chemistry":
        passed = cpassed;
        failed = cfailed;
        timespent = ctime;

        break;
      default:
        passed = 0;
        failed = 0;
        timespent=0;
    }
  
    return {
      labels: ["Passed", "Failed","timespent"], // Only Passed & Failed
      datasets: [
        {
          label: `${subject} Passed`,
          data: [passed, 0,0], // Passed Value
          backgroundColor: ["blue", "grey","grey"], // Hide second bar
        },
        {
          label: `${subject} Failed`,
          data: [0, failed,0], // Failed Value
          backgroundColor: ["grey", "yellow","grey"], // Hide first bar
        },
        {
          label: `${subject} timespent`,
          data: [0,0, timespent], // Failed Value
          backgroundColor: ["grey", 'grey',"red"], // Hide first bar

        }
      ],
    };
  };
  

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setAuthenticate(true);
      setUsername("user");
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const showPerformanceBox = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
    
   {isMobile? (
    <>

    <div>

    <Navbar expand="lg" fixed="top" style={{ height: "95px", backgroundColor: "#f8f9fa", zIndex: 1000, borderBottom: "2px solid #ccc", padding: "10px 20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
          {/* Left side content, if any */}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to='/subjectselect'>
            <span style={{ position: "fixed", left: "6px", top: "28px", fontSize: "17px", fontWeight: "bold", color: "maroon", textDecoration: "none", padding: "5px" }}>
              Scisteps
            </span>
          </Link>
          <button style={{ width: '42px', height: '40px', marginRight:'20px', borderRadius: "20px", backgroundColor: "black", color: "white", cursor: "pointer" }} onClick={showPerformanceBox}>
            <img src={leadericon} alt="Sections" style={{ width: '30px', height: '30px' }} />
          </button>

          {authenticate ? (
            <>
              <Button variant="primary" onClick={() => setShowSignup(true)} style={{ marginRight: "10px" }}>Signup</Button>
              <Button variant="secondary" onClick={() => setShowLogin(true)}>Login</Button>
            </>
          ) : (
            <>
              <span style={{ marginRight: "29px", fontSize: "15px" }}>Welcome, {username}!</span>
              <Button variant="danger" style={{width:'70px',height:'50px',fontSize:'13px'}} onClick={handleSignOut}>Sign Out</Button>
            </>
          )}
        </div>
      </div>
    </Navbar>

    {/* Signup Modal */}
    <Modal show={showSignup} onHide={() => setShowSignup(false)} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Sign Up</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <Signup onClose={() => setShowSignup(false)} />
      </Modal.Body>
    </Modal>

    {/* Login Modal */}
    <Modal show={showLogin} onHide={() => setShowLogin(false)} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <Login onClose={() => setShowLogin(false)} />
      </Modal.Body>
    </Modal>

   {/* Performance Modal */}
   <Modal show={showModal} onHide={closeModal} centered>
<Modal.Header closeButton>
  <Modal.Title>Performance</Modal.Title>
</Modal.Header>
<Modal.Body>
  <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
    <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
      Welcome, {username}!
    </span>
    {["Math", "Physics", "Chemistry"].map((subject) => (
      <div key={subject} style={{ marginBottom: "30px" }}>
        <h5>{subject} - Passed vs Failed</h5>
        <Bar data={getChartData(subject)} />

        {/* Time Spent Horizontal Bar Chart */}
        <h5>{subject} - Time Spent</h5>
        <div style={{ height: "100px", width: "100%" }}> 
<Bar
  data={{
    labels: ["Time Spent"],
    datasets: [
      {
        label: `${subject} Time Spent (mins)`,
        data: [subject === "Math" ? mtime : subject === "Physics" ? ptime : ctime],
        backgroundColor: "red",
      },
    ],
  }}
  options={{
    indexAxis: "y", // Makes it horizontal
    maintainAspectRatio: false, // Prevents default stretching
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  }}
/>
</div>

      </div>
    ))}
  </div>
</Modal.Body>
</Modal>


</div>
</>
   ): (

    <>

    <div>

    <Navbar expand="lg" fixed="top" style={{ height: "95px", backgroundColor: "#f8f9fa", zIndex: 1000, borderBottom: "2px solid #ccc", padding: "10px 20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
          {/* Left side content, if any */}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to='/subjectselect'>
            <span style={{ position: "fixed", left: "6px", top: "28px", fontSize: "17px", fontWeight: "bold", color: "maroon", textDecoration: "none", padding: "5px" }}>
              Scisteps
            </span>
          </Link>
          <button style={{ width: '45px', height: '45px', borderRadius: "20px",marginRight:'50px', backgroundColor: "black", color: "white", cursor: "pointer" }} onClick={showPerformanceBox}>
            <img src={leadericon} alt="Sections" style={{ width: '30px', height: '30px' }} />
          </button>

          {authenticate ? (
            <>
              <Button variant="primary" onClick={() => setShowSignup(true)} style={{ marginRight: "10px" }}>Signup</Button>
              <Button variant="secondary" onClick={() => setShowLogin(true)}>Login</Button>
            </>
          ) : (
            <>
              <span style={{ marginRight: "29px", fontSize: "15px" }}>Welcome, {username}!</span>
              <Button variant="danger" onClick={handleSignOut}>Sign Out</Button>
            </>
          )}
        </div>
      </div>
    </Navbar>

    {/* Signup Modal */}
    <Modal show={showSignup} onHide={() => setShowSignup(false)} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Sign Up</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <Signup onClose={() => setShowSignup(false)} />
      </Modal.Body>
    </Modal>

    {/* Login Modal */}
    <Modal show={showLogin} onHide={() => setShowLogin(false)} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <Login onClose={() => setShowLogin(false)} />
      </Modal.Body>
    </Modal>

   {/* Performance Modal */}
   <Modal show={showModal} onHide={closeModal} centered>
<Modal.Header closeButton>
  <Modal.Title>Performance</Modal.Title>
</Modal.Header>
<Modal.Body>
  <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
    <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
      Welcome, {username}!
    </span>
    {["Math", "Physics", "Chemistry"].map((subject) => (
      <div key={subject} style={{ marginBottom: "30px" }}>
        <h5>{subject} - Passed vs Failed</h5>
        <Bar data={getChartData(subject)} />

        {/* Time Spent Horizontal Bar Chart */}
        <h5>{subject} - Time Spent</h5>
        <div style={{ height: "100px", width: "100%" }}> 
<Bar
  data={{
    labels: ["Time Spent"],
    datasets: [
      {
        label: `${subject} Time Spent (mins)`,
        data: [subject === "Math" ? mtime : subject === "Physics" ? ptime : ctime],
        backgroundColor: "red",
      },
    ],
  }}
  options={{
    indexAxis: "y", // Makes it horizontal
    maintainAspectRatio: false, // Prevents default stretching
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  }}
/>
</div>

      </div>
    ))}
  </div>
</Modal.Body>
</Modal>


</div>
</>
   )} 
   
   </>


  );
};

export default PhysicsHeader;
