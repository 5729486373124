import { useState, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import { Link } from 'react-router-dom';

const NotificationPopup = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!clicked) {
      const interval = setInterval(() => {
        setShowPopup(true);
      }, 5000); // Show popup every 5 seconds

      return () => clearInterval(interval);
    }
  }, [clicked]); // Stops when clicked

  const handleClick = () => {
    setClicked(true);
    // setShowPopup(false);
  };

  return (
    <div style={{ position: "relative", padding: "10px" }}>
      {showPopup && !clicked && (
        <div
          style={{
            position: "fixed",
            bottom: "7px",
            right: "-5px",
            background: "#ffcc00",
            padding: "15px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            gap: "7px",
            cursor: "pointer",
            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
          }}
          onClick={handleClick}
        >
          <FaBell size={20} color="#333" />
          <span style={{ fontWeight: "bold", color: "black" }}> MathSwipe</span>
        </div>
      )}

      {clicked && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            background: "#fff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
            textAlign: "center",
          }}
        >
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>Math Games and animations</p>
          <a href="https://mathswipe.com/about" target="_blank" rel="noopener noreferrer">
          <button
            style={{
              background: "#007bff",
              color: "#fff",
              border: "none",
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
              marginRight:'10px',
              padding:'5px'
            }}
            onClick={() => setClicked(false)}
          >
            Try it now
          </button>
          </a>

          <button
            style={{
              background: "black",
              color: "#fff",
              border: "none",
              padding: "10px",
              borderRadius: "5px",
              cursor: "pointer",
              marginLeft:'5px',
              padding:'5px'
            }}
            onClick={() => setClicked(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
