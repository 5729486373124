import React, { useRef, useState } from "react";
import { signup } from "./auth"; // Make sure this is the correct path
import { gsap } from "gsap";

const Signup = () => {
  const buttonRef = useRef(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [consentVisible, setConsentVisible] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false); // Controls the dialog visibility

  const inputStyle = {
    margin: "9px",
    padding: "10px",
    width: "90%",
    borderRadius: "5px",
    border: "2px solid #ccc",
    fontSize: "16px",
    fontFamily: "Helvetica",
    backgroundColor: "white",
  };

  const buttonStyle = {
    margin: "10px",
    width: "100px",
    padding: "20px 20px",
    borderRadius: "50px",
    backgroundColor: "#8b0000",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "20px",
    position: "fixed",
    top: "300px",
    left: "70px",
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    setConsentVisible(true); // Show the consent form
  };

  const handleSubmit = async () => {
    if (consentGiven) {
      try {
        await signup(name, email, password, dateOfBirth);
        setMessage("Signup successful!");
        console.log("Signup successful!");
        setIsError(false);
      } catch (error) {
        setMessage("Error during signup. Please try again.");
        console.error("Error during signup:", error);
        setIsError(true);
      }
      setDialogVisible(true); // Show dialog box

    } else {
      setMessage("Please provide your consent before proceeding.");
      setIsError(true);
    }
  };

  const handleReject = () => {
    setMessage("You have rejected the consent form. Signup aborted.");
    setConsentVisible(false);
    setIsError(true);
  };

  const clickedButton = () => {
    gsap.to(buttonRef.current, { scale: 0.8, duration: 0.5, ease: "power4.inOut" });
    gsap.to(buttonRef.current, { backgroundColor: "black", duration: 0.5, ease: "power4.inOut" });
  };

  return (
    <div className="signup">
      <form style={{ height: "500px", width: "300px", borderRadius: "30px" }} onSubmit={handleSignUpClick}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={inputStyle}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={inputStyle}
        />
        <input
          type="password"
          placeholder="Password (8 char)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <input
          type="date"
          placeholder="Date of Birth"
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
          style={inputStyle}
        />
        <button ref={buttonRef} onClick={clickedButton} style={buttonStyle} type="submit">
          Sign Up
        </button>
      </form>

      {consentVisible && (
        
        <div style={{ backgroundColor:'white',position:'absolute',top:'40px', padding: "20px", border: "1px solid #ccc", borderRadius: "10px" }}>
          <h3>Consent Form</h3>
          <p>
            By signing up, you agree to the collection of your name, email, date of birth, and your activity data
            on this site. This data will be used for educational analysis to improve your learning experience.
            Your data will be protected and handled in compliance with applicable data protection laws.
          </p>
          <label>
            <input
              type="checkbox"
              checked={consentGiven}
              onChange={(e) => setConsentGiven(e.target.checked)}
            />
            
              __I agree to the terms and conditions.
          </label>
          <div>
            <button
              onClick={handleSubmit}
              style={{ ...buttonStyle, backgroundColor: "green", marginRight: "10px", position: "static" }}
            >
              Submit
            </button>
            <button
              onClick={handleReject}
              style={{ ...buttonStyle, backgroundColor: "red", position: "static" }}
            >
              Reject
            </button>
          </div>
        </div>
      )}
{dialogVisible && (
  <div
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      zIndex: 1000,
      textAlign: "center",
    }}
  >
    <h3>{isError ? "Signup Failed" : "Signup Successful"}</h3>
    <p>{message}</p>
    <button
      onClick={() => setDialogVisible(false)}
      style={{
        marginTop: "10px",
        padding: "10px 20px",
        backgroundColor: "#8b0000",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      Close
    </button>
  </div>
)}

      {message && <div className={`message ${isError ? "error" : ""}`}>{message}</div>}
    </div>
  );
};

export default Signup;
