import React, { useEffect, useRef, useState } from "react";
import { Container, Card } from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player';
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import testtube1 from '../jsons/testube1.json';
import na1 from '../jsons/Na1.json';
import reaction1 from '../jsons/reaction1.json';
import ChemLoader from "../LoadingPages/ChemLoader";

gsap.registerPlugin(Draggable);

const Cprac1 = () => {
  const [ttholder, setttholder] = useState(testtube1);
  const [place1, setplace1] = useState(' ');
  const [place2, setplace2] = useState(' ');
  const [place3, setplace3] = useState(' ');
  const [dropper, setdropper] = useState(0);
  const [isloading, setIsLoading] = useState(true);

  const [metalholder] = useState(na1);
  const tuberef = useRef(null);
  const metalref = useRef(null);
  const playtube = useRef(null);
  const playmetal = useRef(null);
  const originalY = useRef(0); // Ref to store the original Y position
  const draggableRef = useRef(null);  // Ref to store the Draggable instance

  useEffect(()=> {
    setTimeout(() => {
      if(dropper===1){
        gsap.to(metalref.current, { x: -148, duration: 0.7,delay:0.6 });
  
      }
    }, 3100);
  

  },[dropper,metalref]);
  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        if (metalref.current) {
          originalY.current = metalref.current.getBoundingClientRect().top;
          gsap.to(metalref.current, { y: 50, duration: 0 });
        }
      }, 100);      
      gsap.to(metalref.current, { y: 50, duration: 0 });
  
      // Make metalref draggable
      draggableRef.current = Draggable.create(metalref.current, {
        type: "x,y",
        onDrag: function() {
          setplace1("Na");
          if (this.hitTest(tuberef.current, "30%")) {
            setdropper(1);
            
            setplace2("+ H2O");
  
            // If in contact, change to reaction and trigger the animations
            setttholder(reaction1);
            setTimeout(() => {
              setplace3("= NaOH + H2");
              setplace1("2Na");
              setplace2("+ 2H2O");
            }, 3000);
  
            setTimeout(() => {
              if (playtube.current) {
                playtube.current.play();
              }
              if (playmetal.current) {
                playmetal.current.play();
              }
            }, 500);
          } else {
            // If no longer in contact, reset the test tube animation
            setttholder(testtube1);
          //  gsap.to(metalref.current, { x: this.x, duration: 0.7,delay:0.5 });
  
          }
        },
        onDragEnd: function() {
          setdropper(0);
          if (this.hitTest(tuberef.current, "30%")) {
            gsap.to(metalref.current, { x: -148, duration: 0.7,delay:0.5 });
  
            draggableRef.current[0].kill(); // Disable dragging after successful move
  
          }
          // Check the y position after dragging
          const currentY = this.y;
          const position1 = metalref.current.getBoundingClientRect().bottom;
          const place2 = metalref.current.offsetBottom;
            // Reset to original y position if dragged down
            gsap.to(metalref.current, { y: 30, duration: 0.7,delay:0.2 });
             // Adjust this duration as needed
          
         
        }
      });
    }, 3100);
    // Store the original y position when component mounts
   
  }, []);


  useEffect(() => {
    setTimeout(() => {
     setIsLoading(false);
    }, 3000);
    
   }, [isloading]);

  return (
    <>
    {isloading? (
<ChemLoader/>
    ):(
      <div>
      <Container fluid className="d-flex justify-content-center align-items-center" >
         <Card style={{ width: '300px', textAlign: 'center', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
           <Card.Header as="h5">Sodium + Water Experiment</Card.Header>
           <Card.Body className="card-body" style={{ display: 'flex', justifyContent: 'space-between' }}>
             <div ref={tuberef}>
               <Player
                 ref={playtube}
                 src={ttholder}
                 autoplay={false}
                 loop={true}
                 style={{ width: '100%', height: '200px' }}
               />
             </div>
             <div ref={metalref} style={{ cursor: 'pointer' }}>
               <Player
                 ref={playmetal}
                 src={metalholder}
                 autoplay={false}
                 loop={false}
                 keepLastFrame={true}
                 style={{ width: '100%', height: '200px' }}
               />
             </div>
           </Card.Body>
           <Card.Footer style={{ color: 'maroon' }} as="h6">Formula: {place1} {place2}{place3}</Card.Footer>
         </Card>
       </Container>
       </div>
    )}
    </>
  
 
  );
};

export default Cprac1;
