import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import './dashboard.css';
import { Player } from '@lottiefiles/react-lottie-player';
import user1 from '../jsons/level1user.json';
import user2 from '../jsons/level2user.json';
import user3 from '../jsons/level3user.json';
import user4 from '../jsons/level4user.json';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import SignOut from '../Signout';
import Signup from "../Signup";
import newuser from '../jsons/newuser.json';
import NotificationIcon from '../Notified/NotificationIcon'; 

const auth = getAuth();
const db = getFirestore();

const LearningDashboard = ({ message }) => {
  const [isNotificationViewed, setIsNotificationViewed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [hoveredChapter, setHoveredChapter] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [score, setscore] = useState('');
  const [username, setUsername] = useState('');
  const [profilebox, setprofilebox] = useState(newuser); // Store the user UID
  const [userUID, setUserUID] = useState(null); // Store the user UID
  const profileref = useRef(null);
  const [authenticate, setauthenticate] = useState(true);
  const verdictref = useRef(null);
  const [questionno, setquestionno] = useState(1);
  const [level, setLevel] = useState('level1');
  const [fail, setfail] = useState('');
  const [verdict, setverdict] = useState(false);  
  const [unloggedscore, setunloggedscore] = useState(0);
  const [unloggedfail, setunloggedfail] = useState(0);
  const [comment, setComment] = useState('');
  const [showSignup, setShowSignup] = useState(false);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setExpanded(true);
      setHoveredChapter(1);
    }
  };
  const handleSignupSuccess = () => {
    setTimeout(() => {
      setShowSignup(false);

    }, 500);
};
  const handleMouseLeave = () => {
    if (!isMobile) {
      setExpanded(false);
      setHoveredChapter(null);
    }
  };

  const button2Style = {
    //margin: "10px",
    padding: "10px 10px",  
    backgroundColor: "white",
     borderRadius: "20px",
     position: 'fixed',
      border: "3px solid black",
      left: '10px', 
      fontWeight: 'bolder',
       color: 'black',
        fontSize: '15px',
         userSelect: 'none'

  };

  const buttonStyle = {
    //margin: "10px",
    width:'180px',
    height:'50px',
    padding: "20px 20px",
    borderRadius: "20px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "16px",
  
  };


  const handleCommentSubmit = async () => {
    if (userUID && comment.trim() !== '') {
      try {
        await updateDoc(doc(db, 'users', userUID), {
          comments: comment,
        });
        alert('Comment submitted successfully');
        setComment(''); // Clear the text field
      } catch (error) {
        console.error('Error submitting comment: ', error);
        alert('Error submitting comment');
      }
    } else {
      alert('Please enter a comment');
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        console.log('Fetching user data for UID:', user.uid); // Debugging line
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          if (userDoc.data().Level1 > 0) {
            setLevel('level2');
          }
          console.log('Level 2 achieved', userDoc.data().name); // Debugging line
        } else {
          console.error('Error with levels');
        }
      } catch (error) {
        console.error('offline, cant fetch level:', error);
      }
    };
  });

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        console.log('Fetching user data for UID:', user.uid); // Debugging line
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
          setprofilebox(newuser);
        }
      } catch (error) {
        setprofilebox(newuser);
      }
    };
  });

  useEffect(() => {
    const fetchUserData = async (user) => {
      try {
        console.log('Fetching user data for UID:', user.uid); // Debugging line
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setfail(userDoc.data().Mn);
          setprofilebox(user1);
          setUsername(userDoc.data().name);
          setUserUID(user.uid); // Set the user UID
          console.log('Username fetched:', userDoc.data().name); // Debugging line
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUsername('new user'); // Set default username if there's an error
        console.log('unlogged score', unloggedscore);
        setprofilebox(newuser);
        setscore(unloggedscore);
        setfail(unloggedfail);
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setauthenticate(false);
        fetchUserData(user);
      } else {
        setauthenticate(true);
      }
    });
  }, [unloggedscore, unloggedfail]);

  useEffect(() => {
    let unsubscribe;
    if (userUID) {
      const userDocRef = doc(db, 'users', userUID);
      unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setscore(docSnapshot.data().M1 || 0);
          setfail(docSnapshot.data().Mn || 0);
        } else {
          console.error('No such document!');
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userUID]);

  useEffect(() => {
    if (score < 4) {
      // setprofilebox(user1);
    }
    if (score >= 4 && score < 9) {
      const updateLevelAndM1 = async () => {
        try {
          setLevel('level2');
          setprofilebox(user2);
          setTimeout(() => {
            setverdict(true);
          }, 500);
          setTimeout(() => {
            setverdict(false);
          }, 2500);
          setprofilebox(user2);
          await updatelevel1(score);
          await updateM1InDatabase(0);
        } catch (error) {
          console.error('Error updating Level1 or M1:', error);
        }
      };
      updateLevelAndM1();
    } else if (score > 8 && score < 11) {
      setLevel('level3');
      setprofilebox(user3);
      console.log('level3');
    } else if (score > 11) {
      setLevel('level4');
      setprofilebox(user4);
      console.log('level4');
    }
  }, [score]);

  useEffect(() => {
    if (questionno === 4 && level === 'level1') {
      setTimeout(() => {
        // setpassorfail(failedlevel1);
        setverdict(true);
      }, 500);

      setTimeout(() => {
        setverdict(false);
      }, 2500);

      // setquestionno(1);
    }
  }, [questionno, level]);

  const handleSignOut = (signedout) => {
    if (signedout) {
      setauthenticate(true);
    }
  };

  const updatelevel1 = async (score) => {
    try {
      const userDocRef = doc(db, 'users', userUID);
      await updateDoc(userDocRef, { Level1: score });
      console.log('Level1 updated successfully');
    } catch (error) {
      console.error('Error updating Level1:', error);
    }
  };

  const updateM1InDatabase = async (newM1Value) => {
    try {
      const response = await fetch('/api/update-m1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ M1: newM1Value }),
      });

      if (!response.ok) {
        throw new Error('Failed to update M1 in the database');
      }

      const data = await response.json();
      console.log('M1 updated successfully:', data);
    } catch (error) {
      console.error('Error updating M1 in the database:', error);
    }
  };
  const buttonStyle2 = {
    margin: "10px",
    padding: "20px 20px",
    borderRadius: "50px",
    backgroundColor: "#ff3267",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    fontSize: "26px"
  };
  return (
    <div>
      {isMobile ? (
        <div>
          <button className="hamburger-btn" onClick={handleToggle}>
            <FaBars />
          </button>
          <div className="side-dashboard" style={{ width: expanded ? '200px' : '0px' }}>
         
         
          <div >
            <SignOut onSignOut={handleSignOut} />
          </div>
          <div style={{color:'darkblue',position:'absolute',top:'250px',left:'10px',fontFamily:'helvetica'}}>
            
           
             {/* <NotificationIcon message={message} /> */}
          </div>
          <div style={{fontFamily:'helvetica',fontWeight:'bold',fontSize:'25px'}}className="dashboard-links">

<ul>
<li  style={{ borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/">Home</Link></li>

<li  style={{ borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/title">Sections</Link></li>

<li style={{ borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/mlearn">Lesson</Link></li>
   
    <li  style={{ borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/mlearn3">Scenarios</Link></li>
    <li  style={{backgroundColor:'#ff3267', borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/select">Equation Solver</Link></li>

</ul>
</div>
<div style={{position:'absolute',top:'720px',fontFamily:'helvetica',fontWeight:'bold',}} className="dashboard-links">
          <ul>
          <li style={{backgroundColor:'maroon', fontSize:'13px',width:'180px', borderRadius:'10px',left:'-15px',position:'relative'}}>
    <a href="https://sites.google.com/view/mathswipe/home" target="_blank" rel="noopener noreferrer">More about MathSwipe</a>
  </li>
          </ul>
          </div>

{!authenticate && (
  <div style={{position:'absolute',top:'600px',left:'10px'}}  >
            <textarea
              value={comment}
              onChange={handleCommentChange}
              placeholder="Enter your comment here"
            />
            <button style={{...buttonStyle}} onClick={handleCommentSubmit}>Submit Comment</button>
          </div>
)}
          
          {showSignup && <Signup onSignupSuccess={handleSignupSuccess} />}
 
          </div>
        </div>
      ) : (
        <div
          className="side-dashboard3"
          style={{ display: 'flex', flexDirection: 'column', width: '333px' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
         
          <div >
            <SignOut onSignOut={handleSignOut} />
          </div>
          <div>
             {/* <NotificationIcon message={message} /> */}
          </div>
          <div style={{ fontFamily: 'helvetica', position:'relative', fontSize: '20px' }} className="dashboard-links">
            <ul>
            <li  style={{backgroundColor:'#2e99f3', borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/title">Section</Link></li>

            <li style={{backgroundColor:'#2e99f3',borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/mlearn">Lesson</Link></li>
         
   <li  style={{backgroundColor:'#f31c53', borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/mlearn3">Scenario</Link></li>
   <li  style={{backgroundColor:'#732ef3', borderRadius:'20px',left:'-15px',position:'relative'}}><Link to="/select">Equation Solver</Link></li>
   <li style={{backgroundColor:'maroon', fontSize:'15px',width:'180px',top:'330px', borderRadius:'10px',left:'-15px',position:'relative'}}>
    <a href="https://sites.google.com/view/mathswipe/home" target="_blank" rel="noopener noreferrer">More about MathSwipe</a>
  </li>
            </ul>
          </div>
          {showSignup && <Signup onSignupSuccess={handleSignupSuccess} />}

          {!authenticate && (
   <div style={{position:'relative',left:'10px',top:'65px'}}>
   <textarea
     value={comment}
     onChange={handleCommentChange}
     placeholder="Enter your comment here"
   />
   <button style={{...buttonStyle,position:'fixed',top:'550px',left:'5px'}} onClick={handleCommentSubmit}>Submit Comment</button>
 </div>
          )}
       
      
        </div>
      )}
    </div>
  );
};

export default LearningDashboard;
