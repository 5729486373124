import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Player } from '@lottiefiles/react-lottie-player'; // Adjust import if necessary
import shirt from '../jsons/shirts.json';
const QuadraticBusiness = ({ headerText, answersText }) => {
  return (
    <Container className="d-flex flex-column align-items-center py-4">
      <Card className="mb-4" >
        <Card.Body className="text-center">
          <Card.Title>{headerText}</Card.Title>
          <Player
            autoplay
            loop
            src={shirt} // Replace with your own Lottie animation URL
            style={{ height: '200px', width: '200px' }}
          />
        </Card.Body>
      </Card>
      <Card >
        <Card.Body className="text-center">
          <Card.Title>{answersText}</Card.Title>
          {/* Add content for answers here */}
          {answersText}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default QuadraticBusiness;
