import React, { useState, useRef } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Player } from "@lottiefiles/react-lottie-player";
import hydrogenatom from "../jsons/elements/Hydrogenatom.json";
import heliumatom from "../jsons/elements/heliumatom.json";
import oxygenatom from "../jsons/elements/O2.json";

const PeriodicTable = () => {
    const [selectedElement, setSelectedElement] = useState("____");
    const atomref = useRef(null);
    const [element, setElement] = useState(hydrogenatom);
    const [elementDetails, setElementDetails] = useState({});

    // Data for element descriptions
    const elementData = {
        Hydrogen: {
            number: 1,
            protons: 1,
            neutrons: 0,
            electrons: 1,
            animation: hydrogenatom,
        },
        Helium: {
            number: 2,
            protons: 2,
            neutrons: 2,
            electrons: 2,
            animation: heliumatom,
        },
        Oxygen:{
            number:8,
            protons:8,
            neutrons:8,
            electrons: 8,
            animation: oxygenatom
        }
        // Add more elements here...
    };

    const handleElementClick = (elementName) => {
        console.log("Clicked element:", elementName); // Debugging log
        setSelectedElement(elementName);
        const details = elementData[elementName];
        if (details) {
            setElement(details.animation);
            setElementDetails(details);
            setTimeout(() => {
                if (atomref.current) {
                    atomref.current.play();
                }
            }, 100);
        }
    };

    const elementStyle = {
        width: "100px",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        margin: "10px",
        cursor: "pointer",
        textAlign: "center",
    };

    return (
        <Container fluid>
            <Row>

   {/* Additional Information Section */}
   <Col xs={12} md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Element Information</Card.Title>
                            <Card.Text>
                                <h2> {selectedElement}</h2>
                                <Player
                                    ref={atomref}
                                    autoplay={true}
                                    loop={true}
                                    src={element}
                                />
                                {selectedElement !== "____" && (
                                    <div style={{ marginTop: "10px" }}>
                                        <p>
                                            <strong>Atomic Number:</strong>{" "}
                                            {elementDetails.number}
                                        </p>
                                        <p>
                                            <strong>Protons:</strong>{" "}
                                            {elementDetails.protons}
                                        </p>
                                        <p>
                                            <strong>Neutrons:</strong>{" "}
                                            {elementDetails.neutrons}
                                        </p>
                                        <p>
                                            <strong>Electrons:</strong>{" "}
                                            {elementDetails.electrons}
                                        </p>
                                    </div>
                                )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Elements Section */}
                <Col xs={12} md={8}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Elements</Card.Title>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                <div
                                    style={elementStyle}
                                    onClick={() => handleElementClick("Hydrogen")}
                                >
                                    Hydrogen
                                </div>
                                <div
                                    style={elementStyle}
                                    onClick={() => handleElementClick("Helium")}
                                >
                                    Helium
                                </div>
                                <div
                                    style={elementStyle}
                                    onClick={() => handleElementClick("Oxygen")}
                                >
                                    Oxygen
                                </div>
                                {/* Add more elements as needed */}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

             
            </Row>
        </Container>
    );
};

export default PeriodicTable;
