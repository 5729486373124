import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col } from 'react-bootstrap';
import linear from '../jsons/linearmotion.json';
import { Player } from "@lottiefiles/react-lottie-player";
import msswipe from '../jsons/mssswipe1.json';
import nextbtn from '../jsons/nextbtn.json';
import CircularButton from "../bootsrap elements/CircularButton";
import CircularButtonback from "../bootsrap elements/CircularButtonback";
import babs1 from '../jsons/babs1.json';
import babs2 from '../jsons/babs2.json';
import babs3 from '../jsons/babs3.json'; // Import babs3
import babs4 from '../jsons/babs4.json'; // Import babs4
import babs5 from '../jsons/babs5linear.json';
import babs5sound from '../jsons/babs5.mp3';
import babs1sound from '../jsons/babs1sound.mp3';
import linearball from '../jsons/linearmotionball.json';
import non_linear from '../jsons/launchballup.json';
import hello from '../sounds/hello.mp3';
import LoadingPage from '../LoadingPages/LoadingPage';
import PhysicsHeader from "../PhysicsHeader";
import Timer from "../Timer"; // Import the Timer component
import ProgressBar from '../LoadingPages/ProgressBar';
import wronganswer from '../jsons/babswrong.json';
import scisound from '../jsons/scisteps sound.mp3'; // Import scisound
import quesound from '../jsons/havequestion.mp3'; // Import scisound
import runsound from '../jsons/runshop.mp3'; // Import scisound
import timersound from '../jsons/timer.mp3'; // Import scisound
import whichlinear from '../jsons/whichlinear.mp3'; // Import scisound
import babscorrect from '../jsons/babscorrect.json'; // Import scisound
import babscorrectsound from '../jsons/babscorrectsound.mp3';
import wronganswersound from '../jsons/wronganswer.mp3';
import lineardef from '../jsons/LN.mp3';
import lineardefinition from '../jsons/lineardefinition.json';
import nonlineardefinition from '../jsons/nonlineardefinition.json';
import nonlinearsound from '../jsons/sounds/nonlinearsound.mp3';
import { getFirestore, doc, getDoc,updateDoc,onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { updatemEp } from '../authp'; // Import the updateScore function
import { updatemEf } from '../authp'; // Import the updateScore function
import {incrementPhysicsPassed,incrementPhysicsFailed} from '../authp';
import useTimer from "../useTimer";
const auth = getAuth();
const db = getFirestore();

// Import scisound
const Activity = () => {
    const introref = useRef(null);
    const option1ref = useRef(null);
    const option2ref = useRef(null);
    const [options, setoptions] = useState(false);
const [userUID, setUserUID] = useState(null);
const [showPrompt, setShowPrompt] = useState(false);
const [rating, setRating] = useState(0);

    const [babirye, setbabirye] = useState(babs1);
    const [counter, setCounter] = useState(1); // Add counter state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  const [topanime, settopanime] = useState(0);
  const [showTimer, setShowTimer] = useState(false); // Manage the visibility of the Timer component
  const [subtopicno, setsubtopicno] = useState("01");
  const [progressvalue, setprogressvalue] = useState(0);
  const [scorevalue, setscorevalue] = useState(0);
  const [babsound, setbabsound] = useState(babs1sound);
  const [answerset, setanswerset] = useState(true);

    const audioref = useRef(null);
    const audioref2 = useRef(null); // New audio reference
    useTimer(userUID, "physics"); // Track time for Physics

    useEffect(() => {
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setUserUID(user.uid);
          } else {
            setUserUID(null);
          }
        });
      
        return () => unsubscribe(); // Cleanup listener on component unmount
      }, []);

      useEffect(() => {
        if (progressvalue === 50) {
          setShowPrompt(true);
        } 
      }, [progressvalue,showPrompt]);
    

    useEffect(() => {
        const timer = setTimeout(() => {
            if (audioref2.current) {
                audioref2.current.play().catch((error) => {
                    console.error("Audio play interrupted:", error);
                });
            }
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    const wronganswerfunction = () => {
        handlePhysicsFailed();

        setanswerset(false);
            setTimeout(() => {
                setbabirye(wronganswer);
                setbabsound(wronganswersound);
                if (introref.current) {
                    introref.current.play();
                }
            }, 500);
    };
    const correctanswerfunction = () => {
        handlePhysicsPassed();
        setscorevalue(scorevalue+1);

        if(answerset===true){
            setscorevalue(scorevalue+1);
        }
        setTimeout(() => {
            setbabirye(babscorrect);
            setbabsound(babscorrectsound);
            if (introref.current) {
                introref.current.play();
            }
        }, 500);
  
};
const handlePhysicsFailed = async () => {
    if (!userUID) {
      console.error("User UID not available yet");
      return;
    }
    await incrementPhysicsFailed(userUID);
  };
  
const handlePhysicsPassed = async () => {
    if (!userUID) {
      console.error("User UID not available yet");
      return;
    }
    await incrementPhysicsPassed(userUID);
  };
    

    const nextanimation = () => {
        setprogressvalue((prevProgress) => prevProgress + 10);
        
        // Use the previous state of the counter
        setCounter((prevCounter) => {
            if (prevCounter === 1) {
                setbabirye(babs2);
                setbabsound(quesound);
            } else if (prevCounter === 2) {
                setoptions(true);
                setbabirye(babs5);
                setbabsound(whichlinear);

            } else if (prevCounter === 3) {
                setoptions(false);
                setbabirye(lineardefinition);
                setbabsound(lineardef);
            } 
            else if (prevCounter === 4) {
                setoptions(false);
                setbabirye(nonlineardefinition);
               setbabsound(nonlinearsound);
            } 
         
          
          
            return prevCounter + 1; // Increment counter safely
        });
        
        setTimeout(() => {
            if (introref.current) {
                introref.current.play();
                if(audioref.current){
                    audioref.current.play();
                }
                else if (audioref2.current){
                    audioref2.current.play();
                }
            }
        }, 600);
    };
    const goback = () => {
        setprogressvalue((prevProgress) => prevProgress - 10);
        
        // Use the previous state of the counter
        setCounter((prevCounter) => {
              if (prevCounter === 2) {
                setbabirye(babs2);
                setbabsound(quesound);

            } else if (prevCounter === 3) {
                setoptions(true);
                setbabirye(babs5);
                setbabsound(whichlinear);
            } 
           
            return prevCounter - 1; // Increment counter safely
        });
        
        setTimeout(() => {
            if (introref.current) {
                introref.current.play();
            }
        }, 600);
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 3600);
    
        return () => clearTimeout(timer);
      }, []);

      const handleShowTimer = () => {
        setShowTimer(true); // Display the timer when user clicks Timer in the dropdown
      };
    

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const handleAudioLoad3 = () => {
        if (audioref.current) {
            audioref.current.play().catch((error) => {
                console.error("Audio play interrupted:", error);
            });
        }
    };
    const handleAudioLoad2 = () => {
        if (audioref2.current) {
            audioref2.current.play().catch((error) => {
                console.error("Audio play interrupted:", error);
            });
        }
    };
    const squareStyle = {
        width: '90px',
        height: '65px',
        backgroundColor: 'skyblue',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'20px',
     
      
      };
      const squareStyle2 = {
        width: '50%',
        height: '65px',
        backgroundColor: 'skyblue',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:'20px',

      
      };
      const scoreStyle = {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: 'black',
      };
      const scoreStyle2 = {
        fontSize: '15px',
        fontWeight: 'bolder',
        color: 'black',
      };
    return (
        <>
        {isMobile? (
             <>

             {isLoading? (
              <LoadingPage />
      
             ):(
              <div style={{position:'absolute'}}>
                 <div>
                 <PhysicsHeader onTimerClick={handleShowTimer} /> {/* Pass the function to PhysicsHeader */}
                 </div>
                 <Container className="d-flex justify-content-center align-items-center">
              <Row className="justify-content-center flex-wrap">
              {showTimer && (
  <div style={{
    position: 'absolute',
    top: '250px',
    left: '50%',
    zIndex: 10, // Ensures it's in front of the introref animation
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Add background for better visibility
    padding: '20px',
    borderRadius: '10px'
  }}>
    <Timer />
  </div>
)}

              </Row>
            </Container>
              <Container className="d-flex justify-content-center align-items-center">
                  <Row className="justify-content-center flex-wrap">
                      {nextbtn && !showTimer && (
                          <Col xs={12} md={4} lg={5}>
                              <Card style={{ width: '400px', height: '705px', textAlign: 'center',    position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)', }}>
                              <Card.Header style={{height:'230px',backgroundColor:'skyblue'}}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
      <div style={{ display: 'flex',flexDirection:'row', fontFamily:'helvetica',fontWeight:'bold' }}>
       <div style={{fontSize:'10px', fontWeight:'bolder',marginTop:'15px',color:'red',marginLeft:'10px',marginRight:'10px'}}>
        {subtopicno}
       </div>
       <div style={{fontSize:'13px',marginTop:'15px'}}>
        INTRODUCTION
       </div>
       
       <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
       <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>
      
      <div style={{ width: '150px',marginTop:'8px',marginLeft:'5px' }}> 
      <ProgressBar progress={progressvalue} label="Completed"/>
      
      </div>
      
      <Player>
                    <audio autoplay={true} ref={audioref} src={babsound} type="audio/mp3" onLoadedData={handleAudioLoad3} />
                  </Player>
      
                  <Player>
                      <audio ref={audioref2} src={scisound} onLoadedData={handleAudioLoad2} type="audio/mp3" />
                  </Player>
      
       </div>
       
        {/* New Row with Text Options */}
      
      
        {/* Original Row with Equations */}
        
      
      
      </div>
      
      <div style={{alignItems:'center'}}>
         <div style={squareStyle2}>
         <div style={scoreStyle2}>Score</div>
         <div style={scoreStyle2}>{scorevalue}</div>
       </div>
       </div>
      </div>
      
      </Card.Header>
      
                                  <Card.Body style={{ border: '1px solid #9B9B9B', boxShadow: 'none'  }} >
                                    <>
                                     {showPrompt? (
                                      <div className="end-prompt">
 <h2>End of Exercise</h2>
 <h4>Updates will be available soon </h4>
 <div className="buttons">
   <button onClick={() => window.location.href = "/physicsp1"}>Try Next Subtopic</button>
   <button onClick={() => window.location.href = "/activity"}>Repeat exercise</button>
 </div>

 {/* Rating Component */}
 <div className="rating">
   <p>Rate this exercise:</p>
   {[1, 2, 3, 4, 5].map((star) => (
     <span
       key={star}
       style={{
         fontSize: "24px",
         cursor: "pointer",
         color: star <= rating ? "gold" : "gray",
       }}
       onClick={() => setRating(star)}
     >
       ★
     </span>
   ))}
 </div>
</div>
                                     ):(
                                      <div style={{height:'100%', display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
                                      {options && (
         <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
           <div onClick={correctanswerfunction} style={{ flex: 1, borderRight: '2px solid #000', paddingRight: '10px' }}>
         <Player ref={option1ref} autoplay={true} loop={true}  src={linearball} style={{ width: '150px',zIndex:2,cursor:'crosshair', height: '150px',position:'absolute',top:'150px' }} />
       
         </div>
         <div style={{width: '1px', height: '40px', position:'fixed',backgroundColor: 'black', margin: '0 15px',position:'absolute',top:'250px',right:'190px'}}></div>
 
         <div onClick={wronganswerfunction}>
         <Player ref={option2ref} autoplay={true} loop={true} src={non_linear} style={{ width: '150px',zIndex:3,cursor:'crosshair', height: '150px',position:'absolute',top:'150px',right:'5px' }} />
       
         </div>
       </div>
                                      )}
  <div>
  <Player ref={introref} autoplay={true} loop={false} keepLastFrame={true} src={babirye} style={{ width: '400px', height: '400px' }} />
 
  </div>
                                     
                                      
                                       </div>
                                     )}
                                    </> 
      
                                  </Card.Body>
                                  <Card.Footer style={{ border: '1px solid #9B9B9B' }} className="d-flex justify-content-between">
                                  <div onClick={goback}>
      
                                      <CircularButtonback />
                                      </div>
      
                                      <div onClick={nextanimation}>
                                          <CircularButton />
                                      </div>
                                  </Card.Footer>
                              </Card>
                          </Col>
                      )}
                  </Row>
              </Container>
          </div>
             )}
                     
                     </>
        ):(
             <>

             {isLoading? (
              <LoadingPage />
      
             ):(
              <div>
                 <div>
                 <PhysicsHeader onTimerClick={handleShowTimer} /> {/* Pass the function to PhysicsHeader */}
                 </div>
                 <Container className="d-flex justify-content-center align-items-center">
              <Row className="justify-content-center flex-wrap">
                {showTimer && (
                  <Col xs={12} md={4} lg={5}>
                    <Timer /> {/* Show the Timer component */}
                  </Col>
                )}
              </Row>
            </Container>
              <Container className="d-flex justify-content-center align-items-center">
                  <Row className="justify-content-center flex-wrap">
                      {nextbtn &&   (
                          <Col xs={12} md={4} lg={5}>
                              <Card style={{ width: '500px', height: '605px', textAlign: 'center',    position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)', }}>
                              <Card.Header style={{height:'130px',backgroundColor:'skyblue'}}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
      <div style={{ display: 'flex',flexDirection:'row', fontFamily:'helvetica',fontWeight:'bold' }}>
       <div style={{fontSize:'10px', fontWeight:'bolder',marginTop:'15px',color:'red',marginLeft:'10px',marginRight:'10px'}}>
        {subtopicno}
       </div>
       <div style={{fontSize:'13px',marginTop:'15px'}}>
        INTRODUCTION
       </div>
       
       <div style={{display:'flex',flexdirection:'column',fontSize:'15px'}}>
       <div style={{width: '1px', height: '40px', marginTop:'14px',backgroundColor: 'black', margin: '0 15px'}}></div>
      
      <div style={{ width: '200px',marginTop:'8px',marginLeft:'5px' }}> 
      <ProgressBar progress={progressvalue} label="Completed"/>
      
      </div>
      
      <Player>
                    <audio autoplay={true} ref={audioref} src={babsound} type="audio/mp3" onLoadedData={handleAudioLoad3} />
                  </Player>
      
                  <Player>
                      <audio ref={audioref2} src={scisound} type="audio/mp3" />
                  </Player>
      
       </div>
       
        {/* New Row with Text Options */}
      
      
        {/* Original Row with Equations */}
        
      
      
      </div>
      
      <div style={{alignItems:'center'}}>
         <div style={squareStyle}>
         <div style={scoreStyle}>Score</div>
         <div style={scoreStyle}>{scorevalue}</div>
       </div>
       </div>
      </div>
      
      </Card.Header>
      
                                  <Card.Body style={{ border: '1px solid #9B9B9B', boxShadow: 'none'  }} >
                                     {showPrompt ? (
                             <div className="end-prompt">
                             <h2>End of Exercise</h2>
                             <h4>Updates will be available soon </h4>
                             <div className="buttons">
                               <button onClick={() => window.location.href = "/physicsp1"}>Try Next Subtopic</button>
                               <button onClick={() => window.location.href = "/activity"}>Repeat exercise</button>
                             </div>
                            
                             {/* Rating Component */}
                             <div className="rating">
                               <p>Rate this exercise:</p>
                               {[1, 2, 3, 4, 5].map((star) => (
                                 <span
                                   key={star}
                                   style={{
                                     fontSize: "24px",
                                     cursor: "pointer",
                                     color: star <= rating ? "gold" : "gray",
                                   }}
                                   onClick={() => setRating(star)}
                                 >
                                   ★
                                 </span>
                               ))}
                             </div>
                            </div>
                                        
                                     ): (
                                      <div style={{height:'100%', display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
                                      {options && (
         <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexdirection:'column'}}>
           <div  onClick={correctanswerfunction} style={{ flex: 1, borderRight: '2px solid #000', paddingRight: '10px' }} >
           <Player ref={option1ref} autoplay={true} loop={true}  src={linearball} style={{ width: '150px',zIndex:2,cursor:'crosshair', height: '150px',position:'absolute',top:'50px' }} />
      
         </div>
         <div style={{width: '1px', height: '40px', position:'fixed',backgroundColor: 'black', margin: '0 15px',position:'absolute',top:'160px',right:'205px'}}></div>
 
         <div onClick={wronganswerfunction}>
         <Player ref={option2ref} autoplay={true} loop={true} src={non_linear} style={{ width: '150px',zIndex:3,cursor:'crosshair', height: '150px',position:'absolute',top:'50px',right:'5px' }} />
       
         </div>
       </div>
                                      )}
                                     
                                       <div>
                                       <Player ref={introref} autoplay={true} loop={false} keepLastFrame={true} src={babirye} style={{ width: '400px', height: '400px' }} />
       
                                       </div>
                                       </div>
       
                                     )}
                                    
                                  </Card.Body>
                                  <Card.Footer style={{ border: '1px solid #9B9B9B' }} className="d-flex justify-content-between">
                                  <div onClick={goback}>
      
                                      <CircularButtonback />
                                      </div>
      
                                      <div onClick={nextanimation}>
                                          <CircularButton />
                                      </div>
                                  </Card.Footer>
                              </Card>
                          </Col>
                      )}
                  </Row>
              </Container>
          </div>
             )}
                     
                     </>
        )}
       
  </>
    );
};

export default Activity;
